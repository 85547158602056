import angular from 'angular';
import moment from 'moment';
import * as lodash from 'lodash';
import { TipoSolicitacaoEnum } from '../../../../../shared/enums/TipoSolicitacaoEnum';
import { IFerias } from '../../models/ferias.model';
import { ITrabalhador } from '../../../../Trabalhadores/models/trabalhador.model';
import { IMarcacao } from '../../models/marcacao.model';
import { IPeriodoAquisitivo } from '../../models/periodoaquisitivo.model';
import { Empresa, Estabelecimento } from '../../../../../core/usuario/models/usuario-model';
import { TipoColaboradorEnum } from '../../../../../shared/enums/TipoColaboradorEnum';
import { MeurhFeriasService } from '../../ferias.service';
import { ListagemService } from '../../../../../shared/services/listagem.service';
import { IErrosPossiveis, IMensagensErros } from '../../models/erros.models';
import { TrabalhadoresService } from '../../../../Trabalhadores/trabalhadores.service';

export class MeurhFeriasMarcacoesNewEditFormController implements angular.IController {
	static $inject = [
		'$scope',
		'$rootScope',
		'$attrs',
		'NewToaster',
		'$http',
		'nsjRouting',
		'$q',
		'$stateParams',
		'$state',
		'MeurhFeriasService',
		'ListagemService',
		'TrabalhadoresService',
	];

	public form: angular.IFormController;
	public entity: IFerias;
	public edit: boolean;
	public busy: boolean;
	public buscouPeriodo: boolean;
	public buscouMarcacoes: boolean;
	public periodosaquisitivos: Array<{ periodoaquisitivo: string }> = [];
	public selectedPeriodo: any;
	public outrasSolicitacoes: any = [];
	public tree_data: Array<any> = [];
	public erroPreenchimento: boolean;
	public datasFeriados: Array<any> = [];
	public folgasTrabalhador: any = {};
	public marcouAdto13SalarioNoPeriodo: boolean;
	public diasConceder: Array<number> = lodash.range(30, 4);
	public config = {
		adiantamento: this.$rootScope.configuracoes['ADIANTAMENTO_13_FERIAS'] as boolean,
		bloqueiaDobraDeFerias: this.$rootScope.configuracoes['BLOQUEAR_MARCACAO_DOBRA_FERIAS'] as boolean
	};
	public errosPossiveis: IErrosPossiveis = {
		calculaDias: false,
		calculaDiasVendidos: false,
		verificaQtdDiasPeriodo: false,
		verificaSobrepostos: false,
		calculaDiasTotal: false,
		diasDeGozoProibidos: false,
		dobraDeFerias: false
	};
	public errosPossiveisMensagens: IMensagensErros = {
		calculaDias: '',
		calculaDiasVendidos: '',
		verificaQtdDiasPeriodo: '',
		verificaSobrepostos: '',
		calculaDiasTotal: '',
		diasDeGozoProibidos: '',
		dobraDeFerias: ''
	};
  public avisosPossiveis = {
    precisaMarcarPeriodoAnterior: false,
	dobraDeFerias: false
  };
  public avisosPossiveisMensagens = {
    precisaMarcarPeriodoAnterior: '',
	dobraDeFerias: ''
  };
  public solicitacoesFeriasAgrupadas: any = [];
  public rascunhoApenas: boolean; // quando true, só é possível salvar a solicitação como rascunho

  public filters = {
    empresa: this.$rootScope.session.entities.empresa.empresa,
    estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
  };
  public empresas: Array<Empresa>;
  public estabelecimentos: Array<Estabelecimento>;
  public listagemPermissao: Array<string> = ['criar_ferias'];
  public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

  public configColaboradorHipersuficiente: boolean = this.$rootScope.nsjGlobals.a.configuracoes.COLABORADOR_HIPERSUFICIENTE;
  public colaboradorHipersuficiente: boolean = false;
  public configDiasAntecedenciaFerias: number = this.$rootScope.nsjGlobals.a.configuracoes.QTD_DIAS_ANTECEDENCIA_FERIAS_MRH;
  public dataMinimaInicioGozo: string;

	constructor(
		public $scope: angular.IScope,
		public $rootScope: angular.IScope & {
			temPermissao: (arg: string) => boolean,
			modoGestorPermissao: (arg: string) => boolean,
			temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
			liberadoTela: (arg: string) => boolean,
			session: any,
			nsjGlobals: any,
			configuracoes: any,
		},
		public $attrs: any,
		public NewToaster: any,
		public $http: angular.IHttpService,
		public nsjRouting: any,
		public $q: angular.IQService,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
		public entityService: MeurhFeriasService,
		public ListagemService: ListagemService,
		public TrabalhadoresService: TrabalhadoresService,
	) {
		this.diasConceder[this.diasConceder.length] = 0;
		this.$scope.$on('meurh_solicitacoesferias_feriados_loaded', (event: any, args: any) => {
			// remove datas duplicadas
			let unicos = this.uniqueArray(args);
			this.datasFeriados = angular.copy(unicos);
		});
		this.$scope.$on('meurh_solicitacoesferias_horarios_loaded', (event: any, args: any) => {
			this.folgasTrabalhador = args;

			if (this.folgasTrabalhador.tipo >= 0 && this.folgasTrabalhador.tipo <= 2 && this.folgasTrabalhador.dsrsobredomingoseferiados === null) {
				this.folgasTrabalhador.dsrsobredomingoseferiados = true;
			}

			// monta array de folgas
			this.folgasTrabalhador = this.montaFolgas(this.folgasTrabalhador);
		});
		this.$scope.$watch('$ctrl.entity', (entity: any) => {
			if (this.$state.current.name?.includes('edit')) {
				this.buscaMarcacoesTrabalhador(entity);
				this.buscaPeriodoAquisitivo(entity);
			} else if ($stateParams['solicitacao']) {
				this.entity.datainiciogozo = '';
				this.entity.datafimgozo = '';
				this.entity.diasferiascoletivas = 0;
				this.entity.adto13nasferias = false;
				this.entity.venderdias = false;
				this.buscaMarcacoesTrabalhador(entity);
				this.buscaPeriodoAquisitivo(entity);
			} else if (this.entity.trabalhadorobj) {
				this.buscaMarcacoesTrabalhador(entity.trabalhadorobj);
				this.buscaPeriodoAquisitivo(entity.trabalhadorobj);
			}
		});
	}

	$onInit(): void {
		this.montaListagemVisibilidade();
		this.carregaFeriados();
	}

	montaListagemVisibilidade(): void {
		if (this.$state.current.name?.includes('edit')) {
		  this.filters.empresa = this.entity.estabelecimentoobj.empresa;
		  this.filters.estabelecimento = this.entity.estabelecimentoobj.estabelecimento;
		} else if (this.entity.trabalhadorobj) {
			this.filters.empresa = this.entity.trabalhadorobj.empresaobj.empresa;
		  	this.filters.estabelecimento = this.entity.trabalhadorobj.estabelecimentoobj.estabelecimento;
		}
		this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
		this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
	}

	mudaEstabelecimentos(): void {
		this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
		this.filters.estabelecimento = '';
		this.filtraEmpresaEstabelecimento();
	}

	filtraEmpresaEstabelecimento(): void {
		this.$rootScope.$broadcast('trabalhadorobj_refresh', {
			empresa: this.filters.empresa,
			estabelecimento: this.filters.estabelecimento,
			status: '01',
			tipo_excluir: 1
		});
		this.entity.trabalhadorobj = undefined;
	}

	carregaFeriados() {
		if (this.entity.trabalhadorobj) {
			const estabelecimento = this.entity.trabalhadorobj.estabelecimentoobj ?
				this.entity.trabalhadorobj.estabelecimentoobj.estabelecimento :
				this.entity.trabalhadorobj.estabelecimento;
			this.entityService.getFeriados(estabelecimento, 1);
		}
	}

	uniqueArray(a: any) {
		var seen = {};
		return a.filter((item) => {
			return seen.hasOwnProperty(item) ? false : (seen[item] = true);
		});
	}

	buscaPeriodoAquisitivo(trabalhador: ITrabalhador): void {
		if (trabalhador) {
			this.busy = true;

			this.$http({
				method: 'GET',
				url: this.nsjRouting.generate('meurh_solicitacoesferias_lista_periodos', angular.extend({ id: trabalhador.trabalhador }, [], { 'offset': [], 'filter': {} }, {}), true),
				timeout: this.$q.defer().promise
			}).then((response: any) => {
				this.busy = false;
				this.buscouPeriodo = true;
        this.solicitacoesFeriasAgrupadas = response.data;
        // atribuição para remover warning quando trocar de colaborador
        this.avisosPossiveis.precisaMarcarPeriodoAnterior = false;
        this.precisaMarcarPeriodoAnterior();
				this.montaPeriodoAquisitivoList(response.data);
				this.$rootScope.$broadcast('periodos_aquisitivos_loaded', {
					response: response.data
				});
			});
		}
	}

	buscaMarcacoesTrabalhador(trabalhador: ITrabalhador): void {
		if (trabalhador) {
			this.$http({
				method: 'GET',
				url: this.nsjRouting.generate('meurh_solicitacoesferias_get_agrupado', angular.extend({ id: trabalhador.trabalhador }, [], { 'offset': [], 'filter': {} }, {}), true),
				timeout: this.$q.defer().promise
			}).then((response: any) => {
				this.outrasSolicitacoes = angular.copy(response.data);
				this.buscouMarcacoes = true;
				this.montaEntity(response.data);
				this.verificaAdto13SalarioNoPeriodo(this.entity);
				this.$rootScope.$broadcast('solicitacoes_agrupadas_loaded', {
					response: response.data
				});
			});
		}
	}

	buscaFolgasTrabalhador(trabalhador: ITrabalhador): void {
		if (trabalhador) {
			this.entityService.getHorarios(trabalhador.trabalhador);
		}
	}

	montaEntity(data: any): void {
		this.entity.daysVender = lodash.range(Math.trunc((data.direito / 3)), 0);
		this.entity.venderdias = this.entity.diasvendidos ? true : false;
		this.entity.estabelecimento = this.$stateParams['estabelecimento'];
		this.entity.tiposolicitacao = TipoSolicitacaoEnum.FERIAS;
		this.entity.inicioproximoperiodoaquisitivoferias = data.inicioproximoperiodoaquisitivoferias;
	}

	montaPeriodoAquisitivoList(marcacoes: IMarcacao): void {
		this.periodosaquisitivos = [];

		if (marcacoes.inicioperiodoaquisitivoferiasatual && marcacoes.fimperiodoaquisitivoferiasatual) {
			this.periodosaquisitivos.push({ periodoaquisitivo: `${moment(marcacoes.inicioperiodoaquisitivoferiasatual).format('DD/MM/YYYY')} a ${moment(marcacoes.fimperiodoaquisitivoferiasatual).format('DD/MM/YYYY')} (atual)` });

			marcacoes.periodosaquisitivos.forEach((periodo: any) => {
				if (periodo.inicioperiodoaquisitivo !== marcacoes.inicioperiodoaquisitivoferiasatual) {
					this.periodosaquisitivos.push({ periodoaquisitivo: `${moment(periodo.inicioperiodoaquisitivo).format('DD/MM/YYYY')} a ${moment(periodo.fimperiodoaquisitivo).format('DD/MM/YYYY')}` });
				}
			});
		}

		if (this.$state.current.name?.includes('edit')) {
			if (this.entity.datainicioperiodoaquisitivo === marcacoes.inicioperiodoaquisitivoferiasatual) {
				this.selectedPeriodo = this.periodosaquisitivos.find((periodo: any) => {
					return periodo.periodoaquisitivo === `${moment(this.entity.datainicioperiodoaquisitivo).format('DD/MM/YYYY')} a ${moment(this.entity.datafimperiodoaquisitivo).format('DD/MM/YYYY')} (atual)`;
				});
			} else {
				this.selectedPeriodo = this.periodosaquisitivos.find((periodo: any) => {
					return periodo.periodoaquisitivo === `${moment(this.entity.datainicioperiodoaquisitivo).format('DD/MM/YYYY')} a ${moment(this.entity.datafimperiodoaquisitivo).format('DD/MM/YYYY')}`;
				});
			}
		}

		if (this.selectedPeriodo) {
			this.setPeriodoAquisitivo();
		}

	}

	montaFolgas(dias: any) {
		dias.totalFolgas = [];

		if (dias.dsrsobredomingoseferiados) {
			dias.totalFolgas.push(0);
			return dias;
		}

		if (dias.domingo) {
			dias.totalFolgas.push(0);
		}
		if (dias.segunda) {
			dias.totalFolgas.push(1);
		}
		if (dias.terca) {
			dias.totalFolgas.push(2);
		}
		if (dias.quarta) {
			dias.totalFolgas.push(3);
		}
		if (dias.quinta) {
			dias.totalFolgas.push(4);
		}
		if (dias.sexta) {
			dias.totalFolgas.push(5);
		}
		if (dias.sabado) {
			dias.totalFolgas.push(6);
		}
		return dias;
	}

	verificaAdto13SalarioNoPeriodo(entity: IFerias): void {
		let periodosaquisitivos = JSON.parse(JSON.stringify(this.outrasSolicitacoes.periodosaquisitivos));
		if (periodosaquisitivos) {
			for (let periodo in periodosaquisitivos) {
				if (periodosaquisitivos[periodo].hasOwnProperty('solicitacoes')) {
					if ((periodosaquisitivos[periodo].datainicioperiodoaquisitivo === entity.datainicioperiodoaquisitivo && periodosaquisitivos[periodo].datafimperiodoaquisitivo === entity.datafimperiodoaquisitivo) || this.possuiFeriasNaoGozadas(periodosaquisitivos[periodo])) {
						this.marcouAdto13SalarioNoPeriodo = periodosaquisitivos[periodo].solicitacoes.some((solicitacao: any) => solicitacao.adto13nasferias && solicitacao.solicitacao !== entity.solicitacao);

						if (!this.$state.current.name?.includes('edit')) {
							this.entity.adto13nasferias = false;
						}
					} else {
						this.marcouAdto13SalarioNoPeriodo = false;
					}
				}
			}
		}
	}

	setPeriodoAquisitivo(): void {
		if (this.selectedPeriodo) {
			let periodosaquisitivos = this.selectedPeriodo.periodoaquisitivo.match(/\b(\d+\/\d+\/\d+)\b/g);
			let inicioperiodoaquisitivo = periodosaquisitivos[0].split('/').reverse().join('/');
			let fimperiodoaquisitivo = periodosaquisitivos[1].split('/').reverse().join('/');
			let dataAtual = moment();

			this.entity.datainicioperiodoaquisitivo = moment(new Date(inicioperiodoaquisitivo)).format('YYYY-MM-DD');
			this.entity.datafimperiodoaquisitivo = moment(new Date(fimperiodoaquisitivo)).format('YYYY-MM-DD');

			// controle não é necessário para estagiários
			if (this.entity.trabalhadorobj!.tipo !== TipoColaboradorEnum.ESTAGIARIO) {
				// data que será considerada para desativar dias no calendário
				let dataLiberacaoCalendario = (
					this.$rootScope.nsjGlobals.a.configuracoes.MARCAR_FERIAS_ANTES_PERIODO === false
					&& dataAtual < moment(this.entity.datafimperiodoaquisitivo).add(1, 'days')
				)
				? moment(this.entity.datafimperiodoaquisitivo).add(1, 'days')
				: dataAtual;

				let diferencaDatas = dataLiberacaoCalendario.diff(dataAtual, 'days');

				// só deve somar o valor da configuração com a data de liberação do calendário caso a diferença entre os dias seja menor que o valor da configuração
				if (diferencaDatas < this.configDiasAntecedenciaFerias) {
					dataLiberacaoCalendario.add(this.configDiasAntecedenciaFerias, 'days');
				}

				this.dataMinimaInicioGozo = dataLiberacaoCalendario.format('YYYY-MM-DD');
			}
		}
	}

  precisaMarcarPeriodoAnterior(): void {
    let periodoSelecionado = this.entity.datainicioperiodoaquisitivo;
    if (periodoSelecionado) {
      this.solicitacoesFeriasAgrupadas.periodosaquisitivos.some((periodo: any) => {
        if (moment(periodoSelecionado).isAfter(periodo.inicioperiodoaquisitivo) && (periodo.saldo > 0 || periodo.possuirascunho)) {
          this.avisosPossiveis.precisaMarcarPeriodoAnterior = true;
          this.avisosPossiveisMensagens.precisaMarcarPeriodoAnterior = 'Essa marcação de férias pode ser salva como rascunho mas não é possível enviá-la pois o colaborador possui períodos aquisitivos anteriores ao selecionado com pendências de marcações.';
          this.rascunhoApenas = true;
          return true;
        } else {
          this.avisosPossiveis.precisaMarcarPeriodoAnterior = false;
          this.rascunhoApenas = false;
        }
      });
    }
  }

	possuiFeriasNaoGozadas(periodoAquisitivo: IPeriodoAquisitivo): boolean {
		if (periodoAquisitivo.hasOwnProperty('solicitacoes')) {
			return periodoAquisitivo.solicitacoes.some((solicitacao: any) => {
				return moment(solicitacao.datafimgozo).isSameOrAfter(moment());
			});
		}

		return false;
	}

	removeDuplicada(array: Array<any>, entity: IFerias): Array<any> {
		return array.filter((solicitacao: any) => solicitacao.solicitacao !== entity.solicitacao);
	}

	fillPeriodoAquisitivo(entity: IFerias): any {
		let periodosaquisitivos = JSON.parse(JSON.stringify(this.outrasSolicitacoes.periodosaquisitivos));

		if (periodosaquisitivos) {
			for (let periodo in periodosaquisitivos) {
				if (periodosaquisitivos[periodo].hasOwnProperty('solicitacoes')) {
					if (this.$state.current.name?.includes('edit')) {
						periodosaquisitivos[periodo].solicitacoes = this.removeDuplicada(periodosaquisitivos[periodo].solicitacoes, entity);
					}

					if (periodosaquisitivos[periodo].datainicioperiodoaquisitivo === entity.datainicioperiodoaquisitivo && periodosaquisitivos[periodo].datafimperiodoaquisitivo === entity.datafimperiodoaquisitivo) {
						if (!this.$state.current.name?.includes('edit')) {
							periodosaquisitivos[periodo].solicitacoes.push(entity);
						}
					}
				}
			}
		} else {
			let periodoKey = entity.datainicioperiodoaquisitivo + '|' + entity.datafimperiodoaquisitivo;
			periodosaquisitivos[periodoKey] = {
				datainicioperiodoaquisitivo: entity.datainicioperiodoaquisitivo,
				datafimperiodoaquisitivo: entity.datafimperiodoaquisitivo,
				solicitacoes: [
					{
						adto13nasferias: entity.adto13nasferias,
						datafimgozo: entity.datafimgozo,
						datainiciogozo: entity.datainiciogozo,
						datainicioperiodoaquisitivo: entity.datainicioperiodoaquisitivo,
						datafimperiodoaquisitivo: entity.datafimperiodoaquisitivo,
						diasferiascoletivas: entity.diasferiascoletivas,
						diasvendidos: entity.diasvendidos,
						situacao: entity.situacao,
					}
				]
			};
		}

		return periodosaquisitivos;
	}

	zeraVendidos(): void {
		if (this.entity.diasvendidos) {
			this.entity.diasvendidos = 0;
		}
	}

	// verifica array de erros de preenchimento criado para bloquear botões de salvar/enviar
	verificaArrayErros(): void {
		let existeErro = false;
		for (var i in this.errosPossiveis) {
			if (this.errosPossiveis[i] === true) {
				existeErro = true;
				break;
			}
		}

		if (existeErro) {
			this.erroPreenchimento = true;
		} else {
			this.erroPreenchimento = false;
		}
	}

	limpaFormulario(): void {
		this.entity.datainiciogozo = this.entity.datafimgozo = '';
		this.entity.diasferiascoletivas = this.entity.adto13nasferias = null;
		this.entity.venderdias = false;
		this.zeraVendidos();
	}

	valorExiste(valor: any): boolean {
		return valor !== '' && valor !== undefined && valor !== null ? true : false;
	}

	/*
	  *******************
	 * Regras de Negócio
	  *******************
	*/

	verificaBloqueiaDiasAnteriores(): moment.Moment | string {
		// estagiário
		if (this.entity.trabalhadorobj) {
			if (this.entity.trabalhadorobj.tipo === TipoColaboradorEnum.ESTAGIARIO) {
				return moment() > moment(this.entity.datainicioperiodoaquisitivo).add(1, 'days') ? moment().format('YYYY-MM-DD') : moment(this.entity.datainicioperiodoaquisitivo).add(1, 'days').format('YYYY-MM-DD');
			}
		}

		// funcionário
		return this.dataMinimaInicioGozo;
	}

	verificaDia(entity: IFerias): void {
		if (!entity.datainiciogozo) {
			return;
		}

		this.errosPossiveis.diasDeGozoProibidos = false;
		const umDiaAntesDoFeriado = moment(entity.datainiciogozo).add(1, 'days').format('YYYY-MM-DD');
		const doisDiasAntesDoFeriado = moment(entity.datainiciogozo).add(2, 'days').format('YYYY-MM-DD');
		const existeConflito = this.datasFeriados.filter(function (dataFeriado: any) {
			return (dataFeriado === umDiaAntesDoFeriado) || (dataFeriado === doisDiasAntesDoFeriado);
		});
		const diaDeFeriado = this.datasFeriados.filter(function (dataFeriado: any) {
			return (dataFeriado === entity.datainiciogozo);
		});

		// verifica se a data selecionada é um dia de folga ou se antecede dois dias de folga
		this.verificaDiaFolga(entity);
		// verifica se a data selecionada é um feriado ou se antecede dois dias de feriado
		if (diaDeFeriado.length > 0) {
			this.errosPossiveis.diasDeGozoProibidos = true;
			this.errosPossiveisMensagens.diasDeGozoProibidos = 'O início de gozo não pode ser em dia de feriado.';
		} else if (existeConflito.length > 0) {
			this.errosPossiveis.diasDeGozoProibidos = true;
			this.errosPossiveisMensagens.diasDeGozoProibidos = 'O início de gozo não pode ser no período de dois dias que antecedem feriado.';
		}

		this.verificaAvisoDobra(entity);
		this.verificaArrayErros();
	}

	verificaDiaFolga(entity: IFerias): void {
		const diaDaSemana: number = moment(entity.datainiciogozo).day();
		const diaDaSemanaMaisUm: number = moment(entity.datainiciogozo).add(1, 'days').day();
		const diaDaSemanaMaisDois: number = moment(entity.datainiciogozo).add(2, 'days').day();
		const domingo: number = 0;

		const tipoFolgaPadrao: boolean = (this.folgasTrabalhador.tipo >= 0 && this.folgasTrabalhador.tipo <= 2);

		if ((diaDaSemana === domingo && (tipoFolgaPadrao || this.folgasTrabalhador.dsrsobredomingoseferiados === true)) ||
			(this.folgasTrabalhador.totalFolgas && this.folgasTrabalhador.totalFolgas.includes(diaDaSemana))) {
			this.errosPossiveis.diasDeGozoProibidos = true;
			this.errosPossiveisMensagens.diasDeGozoProibidos = 'O início de gozo não pode ser um dia de folga.';

			return;
		}

		if (!(this.colaboradorHipersuficiente && this.configColaboradorHipersuficiente) &&
			((this.folgasTrabalhador.totalFolgas.includes(diaDaSemanaMaisUm) || this.folgasTrabalhador.totalFolgas.includes(diaDaSemanaMaisDois)) ||
			((tipoFolgaPadrao || this.folgasTrabalhador.dsrsobredomingoseferiados === true) && (diaDaSemanaMaisUm === domingo || diaDaSemanaMaisDois === domingo)))) {
			this.errosPossiveis.diasDeGozoProibidos = true;
			this.errosPossiveisMensagens.diasDeGozoProibidos = 'O início de gozo não pode ser no período de dois dias que antecedem folga.';
		}
	}

	async verificaTrabalhadorHipersuficiente(idTrabalhador: string): Promise<any> {

		if (!idTrabalhador) {
			return;
		} else {
			this.busy = true;
		}

		await this.TrabalhadoresService.get(idTrabalhador)
		.then((dadosTrabalhador: any) => {
			this.colaboradorHipersuficiente = dadosTrabalhador.hipersuficiente;
		}).catch ((erro) => {

			this.NewToaster.pop({
                type: 'error',
                title: 'Não foi possível verificar se o trabalhador é hipersuficiente'
            });
		}).finally(() => {
			this.busy = false;
		});

	}

	verificaAvisoDobra(entity: IFerias) {
		let dataFimPeriodoAqs = new Date(entity.datafimperiodoaquisitivo).getTime();
		let dataInicioGozo = new Date(entity.datainiciogozo).getTime();
		if ((dataInicioGozo - dataFimPeriodoAqs) >= 31536000000) {
			if (this.config.bloqueiaDobraDeFerias) {
				this.errosPossiveis.dobraDeFerias = true;
				this.errosPossiveisMensagens.dobraDeFerias = 'O início de gozo selecionado é após a data de dobra.';
			} else {
				this.avisosPossiveis.dobraDeFerias = true;
				this.avisosPossiveisMensagens.dobraDeFerias = 'O início de gozo selecionado é após a data de dobra.';
			}
		} else {
			this.errosPossiveis.dobraDeFerias = false;
			this.avisosPossiveis.dobraDeFerias = false;
		}
	}

	calculaDataFimGozo(entity: any): void {
		if (this.valorExiste(entity.datainiciogozo)) {
      if (entity.diasferiascoletivas > 0) {
        entity.datafimgozo = moment(entity.datainiciogozo).add(entity.diasferiascoletivas - 1, 'days').format('YYYY-MM-DD');
      } else {
        entity.datafimgozo = moment(entity.datainiciogozo).format('YYYY-MM-DD');
      }
		}

		this.verificaSobrepostos(entity);
	}

	calculaDias(entity: IFerias): void {
		// a soma dos saldos dos períodos (dias de férias tirados + dias vendidos) não pode ser maior que a quantidade de dias de férias na qual um funcionário tem direito naquele período aquisitivo (o saldo não pode ser negativo).
		let periodosaquisitivos = this.fillPeriodoAquisitivo(entity);
		this.errosPossiveis.calculaDias = false;
		this.errosPossiveis.calculaDiasTotal = false;
		this.errosPossiveis.calculaDiasVendidos = false;
		this.errosPossiveis.verificaQtdDiasPeriodo = false;
		this.errosPossiveis.verificaSobrepostos = false;
		let periodo = entity.datainicioperiodoaquisitivo + '|' + entity.datafimperiodoaquisitivo;

		if (!periodosaquisitivos.hasOwnProperty(periodo)) {
			periodosaquisitivos[periodo] = {
				datainicioperiodoaquisitivo: entity.datainicioperiodoaquisitivo,
				datafimperiodoaquisitivo: entity.datafimperiodoaquisitivo,
				direito: 30,
				solicitacoes: [entity]
			};
		}

		for (let periodo in periodosaquisitivos) {
			if (periodosaquisitivos[periodo].hasOwnProperty('solicitacoes')) {
				if ((periodosaquisitivos[periodo].datainicioperiodoaquisitivo === entity.datainicioperiodoaquisitivo && periodosaquisitivos[periodo].datafimperiodoaquisitivo === entity.datafimperiodoaquisitivo) || this.possuiFeriasNaoGozadas(periodosaquisitivos[periodo])) {
					let totalDias = 0;

					periodosaquisitivos[periodo].solicitacoes.forEach((element: any) => {
						if (element.hasOwnProperty('diasferiascoletivas')) {
							if (this.valorExiste(element.diasferiascoletivas)) {
								totalDias = Number(totalDias) + Number(element.diasferiascoletivas);
							}
						}
						if (element.hasOwnProperty('diasvendidos')) {
							if (this.valorExiste(element.diasvendidos)) {
								totalDias = Number(totalDias) + Number(element.diasvendidos);
							}
						}
					});

					if (periodosaquisitivos[periodo].solicitacoes.length === 3) {
						let marcouTodosDiasPeriodos = true;
						periodosaquisitivos[periodo].solicitacoes.forEach((element: any) => {
							if (marcouTodosDiasPeriodos) {
								if (element.hasOwnProperty('diasferiascoletivas') && element.hasOwnProperty('diasvendidos')) {
									if (!this.valorExiste(element.diasferiascoletivas) && !this.valorExiste(element.diasvendidos)) {
										marcouTodosDiasPeriodos = false;
									}
								}
							}
						});

						if (marcouTodosDiasPeriodos) {
							if (totalDias < this.outrasSolicitacoes.direito && this.entity.diasferiascoletivas) {
								this.errosPossiveis.calculaDiasTotal = true;
								this.errosPossiveisMensagens.calculaDiasTotal = 'O total da soma dos dias concedidos mais os dias vendidos de um trabalhador deve ser igual ao direito (' + this.outrasSolicitacoes.direito + ') ao definir três períodos.';
								break;
							} else {
								this.errosPossiveis.calculaDiasTotal = false;
							}
						}
					}
					if (totalDias > this.outrasSolicitacoes.direito && this.entity.trabalhadorobj?.tipo !== TipoColaboradorEnum.ESTAGIARIO) {
						this.errosPossiveis.calculaDias = true;
						this.errosPossiveisMensagens.calculaDias = 'O total da soma dos dias concedidos mais os dias vendidos de um trabalhador não deve ultrapassar o saldo do mesmo.';
						this.verificaSobrepostos(entity);
						break;
					} else {
						this.errosPossiveis.calculaDias = false;
						if (periodosaquisitivos[periodo].solicitacoes.length === 1) {
							this.verificaArrayErros();
						}
					}

					this.verificaSobrepostos(entity);
				}
			}
		}
	}

	public calculaDiasVendidos(entity: any): void {
		let periodosaquisitivos = this.fillPeriodoAquisitivo(entity);

		for (let periodo in periodosaquisitivos) {
			if (periodosaquisitivos[periodo].hasOwnProperty('solicitacoes')) {
				if ((periodosaquisitivos[periodo].datainicioperiodoaquisitivo === entity.datainicioperiodoaquisitivo && periodosaquisitivos[periodo].datafimperiodoaquisitivo === entity.datafimperiodoaquisitivo) || this.possuiFeriasNaoGozadas(periodosaquisitivos[periodo])) {
					let totalVendidos = 0;

					periodosaquisitivos[periodo].solicitacoes.forEach((element: any) => {
						if (!!element.diasvendidos) {
							totalVendidos += element.diasvendidos;
						}
					});

					const maxVendas = entity.daysVender.length - (totalVendidos - entity.diasvendidos);

					if (totalVendidos > entity.daysVender.length) {
						this.errosPossiveis.calculaDiasVendidos = true;
						this.errosPossiveisMensagens.calculaDiasVendidos = 'O máximo de dias que podem ser vendidos para o trabalhador é ' + maxVendas + '.';
						this.verificaArrayErros();
						break;
					} else {
						this.errosPossiveis.calculaDiasVendidos = false;
					}
					this.verificaArrayErros();
				}
			}
		}
	}


	verificaSobrepostos(entity: IFerias): void {
		// o usuário não pode marcar períodos de férias sobrepostos.
		let periodosaquisitivos = this.fillPeriodoAquisitivo(entity);

		for (let periodo in periodosaquisitivos) {
			if (periodosaquisitivos[periodo].hasOwnProperty('solicitacoes')) {
				if ((periodosaquisitivos[periodo].datainicioperiodoaquisitivo === entity.datainicioperiodoaquisitivo && periodosaquisitivos[periodo].datafimperiodoaquisitivo === entity.datafimperiodoaquisitivo) || this.possuiFeriasNaoGozadas(periodosaquisitivos[periodo])) {
					let indexEntity = periodosaquisitivos[periodo].solicitacoes.indexOf(entity);
					let estaSobreposto = false;

					periodosaquisitivos[periodo].solicitacoes.forEach((element: any) => {
						let indexElement = periodosaquisitivos[periodo].solicitacoes.indexOf(element);

						if (!estaSobreposto && (indexElement !== indexEntity)) {
							if (this.valorExiste(element.datainiciogozo) && this.valorExiste(element.datafimgozo)) {
								if (((moment(entity.datainiciogozo) >= moment(element.datainiciogozo)
									&& moment(entity.datainiciogozo) <= moment(element.datafimgozo))
									|| (moment(entity.datafimgozo) >= moment(element.datainiciogozo)
										&& moment(entity.datafimgozo) <= moment(element.datafimgozo)))
								) {
									estaSobreposto = true;
								}
								if (((moment(element.datainiciogozo) >= moment(entity.datainiciogozo)
									&& moment(element.datainiciogozo) <= moment(entity.datafimgozo))
									|| (moment(element.datafimgozo) >= moment(entity.datainiciogozo)
										&& moment(element.datafimgozo) <= moment(entity.datafimgozo)))
								) {
									estaSobreposto = true;
								}
							}
						}
					});

					if (estaSobreposto) {
						this.errosPossiveis.verificaSobrepostos = true;
						this.errosPossiveisMensagens.verificaSobrepostos = 'Os períodos de férias não podem ser sobrepostos.';
						this.verificaArrayErros();
						break;
					} else {
						this.errosPossiveis.verificaSobrepostos = false;
					}

					this.verificaArrayErros();
				}
			}
		}
	}

	verificaQtdDiasPeriodo(entity: any): void {
		// caso as férias sejam particionadas, pelo menos um dos períodos de férias deve ser igual ou maior do que 14 dias (sem considerar os dias vendidos naquele período).
		let periodosaquisitivos = this.fillPeriodoAquisitivo(entity);

		for (let periodo in periodosaquisitivos) {
			if (periodosaquisitivos[periodo].hasOwnProperty('solicitacoes')) {
				if ((periodosaquisitivos[periodo].datainicioperiodoaquisitivo === entity.datainicioperiodoaquisitivo && periodosaquisitivos[periodo].datafimperiodoaquisitivo === entity.datafimperiodoaquisitivo) || this.possuiFeriasNaoGozadas(periodosaquisitivos[periodo])) {
					let possivelPeriodoQuatorzeDias: boolean = false;

					if (periodosaquisitivos[periodo].solicitacoes.length > 1) {
						if (periodosaquisitivos[periodo].solicitacoes.length === 3) {
							periodosaquisitivos[periodo].solicitacoes.forEach((element: any) => {
								if (element.diasferiascoletivas >= 14) {
									possivelPeriodoQuatorzeDias = true;
								}
							});
						}

						if (periodosaquisitivos[periodo].solicitacoes.length === 2) {
							if (((30 - (periodosaquisitivos[periodo].solicitacoes[0].diasferiascoletivas + periodosaquisitivos[periodo].solicitacoes[1].diasferiascoletivas)) >= 14 ||
								(periodosaquisitivos[periodo].solicitacoes[0].diasferiascoletivas >= 14) ||
								(periodosaquisitivos[periodo].solicitacoes[1].diasferiascoletivas) >= 14)) {
								possivelPeriodoQuatorzeDias = true;
							}
						}
					} else {
						possivelPeriodoQuatorzeDias = true;
					}

					if (possivelPeriodoQuatorzeDias) {
						this.errosPossiveis.verificaQtdDiasPeriodo = false;
					} else {
						this.errosPossiveis.verificaQtdDiasPeriodo = true;
						this.errosPossiveisMensagens.verificaQtdDiasPeriodo = 'Um dos períodos de férias precisa ser igual ou maior que 14 dias.';
					}

					this.verificaSobrepostos(entity);
				}
			}
		}
	}
}
