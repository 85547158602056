import angular = require('angular');
import moment = require('moment');
import { MeurhFeriasService } from '../../ferias.service';
import { MeurhMarcacoesAnterioresModalService } from '../marcacoesanterioresmodal/marcacoesanteriores.modal.service';
import { IFerias } from '../../models/ferias.model';
import { IMarcacao } from '../../models/marcacao.model';
import { IPeriodoAquisitivo } from '../../models/periodoaquisitivo.model';

export class MeurhFeriasmarcacoesNewController implements ng.IController {
	static $inject = ['$scope', '$stateParams', '$state', 'MeurhFeriasService', 'entity', 'NewToaster', '$rootScope', '$http', '$q', 'nsjRouting', 'MeurhMarcacoesAnterioresModalService'];

	public fields = [
		{
			value: 'periodoaquisitivo',
			label: 'Período aquisitivo',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'created_at',
			label: 'Criada em',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'iniciogozo',
			label: 'Início de gozo',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'diasaconceder',
			label: 'Dias a conceder',
			type: 'integer',
			style: 'title',
			copy: '',
		},
		{
			value: 'diasvendidos',
			label: 'Comprar dias?',
			type: 'integer',
			style: 'title',
			copy: '',
		},
		{
			value: 'fimgozo',
			label: 'Fim de gozo',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'adto13nasferias',
			label: 'Adiantar 13º?',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'situacao',
			label: 'Situação',
			type: 'string',
			style: 'title',
			copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
					<div ng-if="!tableRowEntity.situacao && tableRowEntity.situacao !== 0 && tableRowEntity.situacao !== -1">
					<span>-</span>
					</div>
					<div ng-if="tableRowEntity.situacao === -1" class="label label-default">
						<span>Rascunho</span>
					</div>
					<div ng-if="tableRowEntity.situacao === 0" class="label label-warning">
						<span>Enviada</span>
					</div>
					<div ng-if="tableRowEntity.situacao === 1" class="label label-success">
						<span>Efetuada</span>
					</div>
					<div ng-if="tableRowEntity.situacao === 2" class="label label-danger">
						<span>Fechada</span>
					</div>
              	`;
                return someHtmlVar;
            },
		}
	];

	public tipo: unknown;
	public busy: boolean = false;
	public rascunhoSendoCriado: boolean = false;
	public form: angular.IFormController;
	public constructors: Array<any> = [];
	public outrasSolicitacoes: any = [];
	public marcacoesAnteriores: Array<any>;
	public marcacoesParaSalvar: number;
	public erroPreenchimento: boolean = false;
	public rascunhoApenas: boolean = false; // quando true, só é possível salvar a solicitação como rascunho
	public tree_data: any = [];
	public tree_control: any = {};
	public entities: any = [];

	constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
		public entityService: MeurhFeriasService,
		public entity: IFerias,
		public NewToaster: any,
		public $rootScope: angular.IScope & { codigoHook: number },
		public $http: angular.IHttpService,
		public $q: angular.IQService,
		public nsjRouting: any,
		public MeurhMarcacoesAnterioresModalService: MeurhMarcacoesAnterioresModalService
	) {
		if (Object.keys($stateParams.trabalhador).length !== 0) {
			this.entity.trabalhadorobj = $stateParams.trabalhador;
			this.entity.trabalhador = $stateParams.trabalhador.trabalhador;
		}
	}

	$onInit(): void {
		this.onPeriodosLoaded();
		this.onSubmitError();
		this.$rootScope.codigoHook = this.entity.codigo;
	}

	montaTree(marcacoesTrabalhador: IMarcacao): void | boolean {
		if (Object.keys(marcacoesTrabalhador.periodosaquisitivos).length > 0) {
			this.entities = [];
			let periodos = marcacoesTrabalhador.periodosaquisitivos;

			for (let periodo in periodos) {
				if (periodos[periodo].hasOwnProperty('solicitacoes')) {
					let anoPeriodoAtual = moment(marcacoesTrabalhador.inicioperiodoaquisitivoferias).year();
					let anoPeriodoSolicitacao = moment(periodos[periodo].datainicioperiodoaquisitivo).year();

					if (anoPeriodoSolicitacao >= anoPeriodoAtual || this.possuiFeriasNaoGozadas(periodos[periodo])) {
						periodos[periodo].solicitacoes = periodos[periodo].solicitacoes.filter((solicitacao: any) => {
							return solicitacao.datainiciogozo !== this.entity.datainiciogozo && solicitacao.datafimgozo !== this.entity.datafimgozo && solicitacao.datainicioperiodoaquisitivo !== this.entity.datainicioperiodoaquisitivo && moment(solicitacao.datafimgozo).isSameOrAfter(moment());
						});

						let datainicioperiodoaquisitivo = periodo.split('|')[0];
						let datafimperiodoaquisitivo = periodo.split('|')[1];

						periodos[periodo].solicitacoes.map((marcacao: any) => {
							this.entities.push({
								'periodoaquisitivo': `${moment(datainicioperiodoaquisitivo).format('DD/MM/YYYY')} a ${moment(datafimperiodoaquisitivo).format('DD/MM/YYYY')}`,
								'created_at': moment(marcacao.created_at).format('DD/MM/YYYY'),
								'iniciogozo': moment(marcacao.datainiciogozo).format('DD/MM/YYYY'),
								'diasaconceder': marcacao.diasferiascoletivas,
								'diasvendidos': marcacao.diasvendidos ? marcacao.diasvendidos : 'Não',
								'fimgozo': moment(marcacao.datafimgozo).format('DD/MM/YYYY'),
								'adto13nasferias': marcacao.adto13nasferias ? 'Sim' : 'Não',
								'situacao': marcacao.situacao
							});
						});
					}
				}
			}
		} else {
			this.entities = [];
		}
	}

	possuiFeriasNaoGozadas(periodoAquisitivo: IPeriodoAquisitivo): boolean {
		if (periodoAquisitivo.hasOwnProperty('solicitacoes')) {
			return periodoAquisitivo.solicitacoes.some((solicitacao: any) => {
				return moment(solicitacao.datafimgozo).isSameOrAfter(moment());
			});
		}
	}

	existemMarcacoesAnteriores(): boolean {
		let periodosaquisitivos = JSON.parse(JSON.stringify(this.outrasSolicitacoes.periodosaquisitivos));
		var solicitacoes;

		if (periodosaquisitivos) {
			for (let periodo in periodosaquisitivos) {
				if (periodosaquisitivos[periodo].hasOwnProperty('solicitacoes')) {
					if (periodosaquisitivos[periodo]['solicitacoes'].length > 0) {
						solicitacoes = periodosaquisitivos[periodo]['solicitacoes'];
						return this.contaMarcacoesAnteriores(solicitacoes);
					} else {
						return false;
					}
				}
			}
		} else {
			return false;
		}
	}

	contaMarcacoesAnteriores(solicitacoes: Array<IFerias>): boolean {
		this.marcacoesAnteriores = [];

		solicitacoes.forEach((solicitacao: any) => {
			if (moment(solicitacao.datainiciogozo).isBefore(this.entity.datainiciogozo) && solicitacao.situacao === -1) {
				solicitacao['trabalhadorobj'] = this.entity.trabalhadorobj;

				this.marcacoesAnteriores.push(solicitacao);
			}
		});

		if (this.marcacoesAnteriores.length > 0) {
			return true;
		}

		return false;
	}

	salvar(rascunho: boolean = false): void {
		if (!rascunho && this.existemMarcacoesAnteriores()) {
			let modal = this.MeurhMarcacoesAnterioresModalService.open(this.marcacoesAnteriores);

			modal.result.then((confirmEnviar: boolean) => {
				if (this.form.$valid && !this.entity.$$__submitting && confirmEnviar) {
					this.busy = true;
					this.entity.trabalhador = this.entity.trabalhadorobj.trabalhador;
					this.marcacoesAnteriores.push(this.entity);

					this.marcacoesAnteriores.map((marcacao: any) => {
						marcacao.situacao = 0;
					});

					this.marcacoesAnteriores.forEach((element: any) => {

						if (element.diasferiascoletivas === undefined || element.diasferiascoletivas === null || element.diasferiascoletivas === '') {
							element['diasferiascoletivas'] = 0;
						}

						if (element.datafimgozo === undefined || element.datafimgozo === null || element.datafimgozo === '') {
							element['datafimgozo'] = element['datainiciogozo'];
						}

						if (this.entity.datainiciogozo > this.entity.datafimgozo) {
							this.entity['datafimgozo'] = this.entity['datainiciogozo'];
						}

						if (element.dataaviso) {
							delete element.dataaviso;
						}

						this.entityService.save(element, {
							empresa: this.entity.trabalhadorobj!.empresaobj.empresa,
							estabelecimento: this.entity.trabalhadorobj!.estabelecimentoobj.estabelecimento
						});
					});

					this.marcacoesParaSalvar = this.marcacoesAnteriores.length;

					this.$scope.$on('meurh_solicitacoesferias_submitted', (event: any, args: any) => {
						this.marcacoesParaSalvar = this.marcacoesParaSalvar - 1;
						if (this.marcacoesParaSalvar === 0) {
							this.NewToaster.pop({
								type: 'success',
								title: 'As solicitações de marcações de férias foram criadas com sucesso!'
							});

							this.$state.go('ferias_marcacoes');
						}
					});
				} else {
					this.busy = false;
					if (confirmEnviar) {
						this.NewToaster.pop({
							type: 'error',
							title: 'Ocorreu um erro ao criar as marcações de férias!',
						});
					}
				}
			});
		} else if (this.form.$valid && !this.entity.$$__submitting) {
			this.busy = true;
			this.rascunhoSendoCriado = rascunho;
			this.entity.trabalhador = this.entity.trabalhadorobj!.trabalhador;
			this.entity.situacao = rascunho ? -1 : 0;

			if (this.entity.diasferiascoletivas === undefined || this.entity.diasferiascoletivas === null || this.entity.diasferiascoletivas === '') {
				this.entity['diasferiascoletivas'] = 0;
			}

			if (this.entity.datafimgozo === undefined || this.entity.datafimgozo === null || this.entity.datafimgozo === '') {
				this.entity['datafimgozo'] = this.entity['datainiciogozo'];
			}

			if (this.entity.datainiciogozo > this.entity.datafimgozo) {
				this.entity['datafimgozo'] = this.entity['datainiciogozo'];
			}

			this.entityService.save(this.entity, {
                empresa: this.entity.trabalhadorobj!.empresaobj.empresa,
                estabelecimento: this.entity.trabalhadorobj!.estabelecimentoobj.estabelecimento
            });
			this.$scope.$on('meurh_solicitacoesferias_submitted', (event: any, args: any) => {

				if (this.rascunhoSendoCriado) {
					this.rascunhoSendoCriado = false;
					this.NewToaster.pop({
						type: 'success',
						title: 'O rascunho de marcação de férias foi criado com sucesso!'
					});

					this.$state.go('ferias_marcacoes_show', angular.extend({}, {
						'solicitacao': args.response.data.solicitacao
					}));
				} else {
					this.NewToaster.pop({
						type: 'success',
						title: 'A solicitação de marcação de férias foi criada com sucesso!'
					});

					this.$state.go('ferias_marcacoes_show', angular.extend({}, {
						'solicitacao': args.response.data.solicitacao
					}));
				}
			});
		} else {
			this.NewToaster.pop({
				type: 'error',
				title: 'Ocorreu um erro ao criar a marcação de férias!',
				body: 'Por favor, verifique os campos destacados em vermelho.'
			});
		}
	}

	onPeriodosLoaded(): void {
		this.$scope.$on('solicitacoes_agrupadas_loaded', (event: angular.IAngularEvent, args: any) => {
			this.montaTree(args.response);
		});
	}

	onSubmitSuccess(): void {
		this.$scope.$on('meurh_solicitacoesferias_submitted', (event: angular.IAngularEvent, args: any) => {
			if (this.entityService.constructors.situacao === -1) {
				this.entityService.constructors.id = this.entity.solicitacao;
			}


			if (this.rascunhoSendoCriado) {
				this.rascunhoSendoCriado = false;

				this.NewToaster.pop({
					type: 'success',
					title: 'O rascunho de marcação de férias foi criado com sucesso!'
				});

				this.$state.go('ferias_marcacoes_show', angular.extend({}, {
					'solicitacao': args.response.data.solicitacao
				}));
			} else {
				this.NewToaster.pop({
					type: 'success',
					title: 'A solicitação de marcação de férias foi criada com sucesso!'
				});

				this.$state.go('ferias_marcacoes_show', angular.extend({}, {
					'solicitacao': args.response.data.solicitacao
				}));
			}
		});
	}

	onSubmitError(): void {
		this.$scope.$on('meurh_solicitacoesferias_submit_error', (event: any, args: any) => {
			if (args.response.status === 409) {
				if (confirm(args.response.data.message)) {
					this.entity[''] = args.response.data.entity[''];
					if (this.entity.diasferiascoletivas === undefined || this.entity.diasferiascoletivas === null || this.entity.diasferiascoletivas === '') {
						this.entity['diasferiascoletivas'] = 0;
					}
					if (this.entity.datafimgozo === undefined || this.entity.datafimgozo === null || this.entity.datafimgozo === '') {
						this.entity['datafimgozo'] = this.entity['datainiciogozo'];
					}

					if (this.entity.datainiciogozo > this.entity.datafimgozo) {
						this.entity['datafimgozo'] = this.entity['datainiciogozo'];
					}
					this.entityService.save(this.entity, {
						empresa: this.entity.trabalhadorobj!.empresaobj.empresa,
						estabelecimento: this.entity.trabalhadorobj!.estabelecimentoobj.estabelecimento
					});
				}
			} else {
				this.busy = false;

				if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
					if (args.response.data.message === 'Validation Failed') {
						let message: string = '';
						if (args.response.data.errors.errors) {
							message = args.response.data.errors.errors[0];
						} else if (args.response.data.errors.children) {
							let children = args.response.data.errors.children;
							let childrenMsg: string = '';
							for (let child in children) {
								if (children[child].errors !== undefined) {
									for (let error in children[child].errors) {
										if (children[child].errors.hasOwnProperty(error)) {
											childrenMsg += '<li>' + children[child].errors[error] + '</li>';
										}
									}
								}
							}
							message = 'Os seguintes itens precisam ser alterados: <ul>' + childrenMsg + '</ul>';
						}

						this.NewToaster.pop({
							type: 'error',
							title: 'Erro de Validação',
							body: message,
							bodyOutputType: 'trustedHtml'
						});
					} else {
						this.NewToaster.pop({
							type: 'error',
							title: args.response.data.message
						});
					}
				} else {
					this.NewToaster.pop({
						type: 'error',
						title: 'Ocorreu um erro ao criar a solicitação de férias!',
						body: 'Por favor, verifique os campos em vermelho.'
					});
				}
			}
		});
	}
}
