import angular = require('angular');
import { MeurhAcompanhamentoService } from '../../../Acompanhamento/acompanhamento.service';
import moment = require('moment');
import { MeurhFeriasService } from '../../ferias.service';
import { IMarcacao } from '../../models/marcacao.model';
import { IPeriodoAquisitivo } from '../../models/periodoaquisitivo.model';
import { IFerias } from '../../models/ferias.model';
import { TipoColaboradorEnum } from '../../../../../shared/enums/TipoColaboradorEnum';

export class MeurhFeriasmarcacoesEditController implements ng.IController {
	static $inject = ['$scope', '$stateParams', '$state', 'MeurhFeriasService', 'entity', 'NewToaster', '$rootScope', '$http', '$q', 'nsjRouting', 'MeurhAcompanhamentoService'];

	static col_defs = [
		{
			field: 'created_at',
			displayName: 'Criada em',
		},
		{
			field: 'iniciogozo',
			displayName: 'Início de gozo'
		},
		{
			field: 'diasaconceder',
			displayName: 'Dias a conceder'
		},
		{
			field: 'diasvendidos',
			displayName: 'Comprar dias?'
		},
		{
			field: 'fimgozo',
			displayName: 'Fim de gozo'
		},
		{
			field: 'adto13nasferias',
			displayName: 'Adiantar 13º?'
		},
		{
			field: 'situacao',
			displayName: 'Situação',
			cellTemplate: `
      <div ng-if="!row.branch.situacao && row.branch.situacao !== 0 && row.branch.situacao !== -1">
          <span>-</span>
      </div>
      <div ng-if="row.branch.situacao === -1" class="label label-default">
          <span>Rascunho</span>
      </div>
      <div ng-if="row.branch.situacao === 0" class="label label-warning">
          <span>Enviada</span>
      </div>
      <div ng-if="row.branch.situacao === 1" class="label label-success">
          <span>Efetuada</span>
      </div>
      <div ng-if="row.branch.situacao === 2" class="label label-danger">
          <span>Fechada</span>
      </div>
      `
		}
	];

	public tree_data: any = [];
	public tipo: unknown;
	public busy: boolean = false;
	public buscouSolicitacoes: boolean = false;
	public buscouPeriodos: boolean = false;
	public erroPreenchimento: boolean = false;
	public expandOutrasMarcacoes: boolean;
	public outrasSolicitacoes: any = [];
	public form: angular.IFormController;
	public constructors: Array<any> = [];

	public configDiasAntecedenciaFerias: number = this.$rootScope.nsjGlobals.a.configuracoes.QTD_DIAS_ANTECEDENCIA_FERIAS_MRH;
  	public dataMinimaInicioGozo: string;

	constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
		public entityService: MeurhFeriasService,
		public entity: IFerias,
		public NewToaster: any,
		public $rootScope: angular.IScope & { codigoHook: number, nsjGlobals: any, },
		public $http: angular.IHttpService,
		public $q: angular.IQService,
		public nsjRouting: any,
		public MeurhAcompanhamentoService: MeurhAcompanhamentoService
	) {
		this.tipo = $stateParams['tipo'];
		this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
		this.MeurhAcompanhamentoService.loadParams.finished = false;
		this.MeurhAcompanhamentoService.loadParams.to_load = 3;
		this.MeurhAcompanhamentoService.load();
		this.expandOutrasMarcacoes = false;
	}

	$onInit(): void {
		this.onSolicitacoesLoaded();
		this.onPeriodosLoaded();
		this.onSubmitSuccess();
		this.onSubmitError();
		this.$rootScope.codigoHook = this.entity.codigo;

		let dataAtual = moment();

		// controle não é necessário para estagiários
		if (this.entity.trabalhadorobj!.tipo !== TipoColaboradorEnum.ESTAGIARIO) {
			// data que será considerada para desativar dias no calendário
			let dataLiberacaoCalendario = (
				this.$rootScope.nsjGlobals.a.configuracoes.MARCAR_FERIAS_ANTES_PERIODO === false
				&& dataAtual < moment(this.entity.datafimperiodoaquisitivo).add(1, 'days')
			)
			? moment(this.entity.datafimperiodoaquisitivo).add(1, 'days')
			: dataAtual;

			let diferencaDatas = dataLiberacaoCalendario.diff(dataAtual, 'days');

			// só deve somar o valor da configuração com a data de liberação do calendário caso a diferença entre os dias seja menor que o valor da configuração
			if (diferencaDatas < this.configDiasAntecedenciaFerias) {
				dataLiberacaoCalendario.add(this.configDiasAntecedenciaFerias, 'days');
			}

			this.dataMinimaInicioGozo = dataLiberacaoCalendario.format('YYYY-MM-DD');
		}

	}

	montaTree(marcacoesTrabalhador: IMarcacao): void {
		if (Object.keys(marcacoesTrabalhador.periodosaquisitivos).length > 0) {
			let periodos = marcacoesTrabalhador.periodosaquisitivos;

			for (let periodo in periodos) {
				if (periodos[periodo].hasOwnProperty('solicitacoes')) {
					let anoPeriodoAtual = moment(marcacoesTrabalhador.inicioperiodoaquisitivoferias).year();
					let anoPeriodoSolicitacao = moment(periodos[periodo].datainicioperiodoaquisitivo).year();
					if (anoPeriodoSolicitacao >= anoPeriodoAtual || this.possuiFeriasNaoGozadas(periodos[periodo])) {
						periodos[periodo].solicitacoes = periodos[periodo].solicitacoes.filter((solicitacao: any) => {
							return solicitacao.datainiciogozo !== this.entity.datainiciogozo && solicitacao.datafimgozo !== this.entity.datafimgozo && solicitacao.datainicioperiodoaquisitivo !== this.entity.datainicioperiodoaquisitivo && moment(solicitacao.datafimgozo).isSameOrAfter(moment());
						});

						let children = periodos[periodo].solicitacoes.map((marcacao: any) => {
							return {
								'Período aquisitivo': '-',
								'created_at': moment(marcacao.created_at).format('DD/MM/YYYY'),
								'iniciogozo': moment(marcacao.datainiciogozo).format('DD/MM/YYYY'),
								'diasaconceder': marcacao.diasferiascoletivas,
								'diasvendidos': marcacao.diasvendidos ? marcacao.diasvendidos : 'Não',
								'fimgozo': moment(marcacao.datafimgozo).format('DD/MM/YYYY'),
								'adto13nasferias': marcacao.adto13nasferias ? 'Sim' : 'Não',
								'situacao': marcacao.situacao
							};
						});

						let datainicioperiodoaquisitivo = periodo.split('|')[0];
						let datafimperiodoaquisitivo = periodo.split('|')[1];

						// faz a tree vir aberta quando há solicitações de apenas 1 período aquisitivo
						if (Object.keys(periodos).length === 1) {
							this.tree_data.expandAll = true;
							this.tree_data.init = true;
						}

						this.$rootScope.$on('expandedInit', () => {
							this.tree_data.init = false;
						});

						if (children.length > 0) {
							this.tree_data.push(
								{
									'Período aquisitivo': `${moment(datainicioperiodoaquisitivo).format('DD/MM/YYYY')} a ${moment(datafimperiodoaquisitivo).format('DD/MM/YYYY')}`,
									'created_at': '-',
									'iniciogozo': '-',
									'diasaconceder': '-',
									'diasvendidos': '-',
									'fimgozo': '-',
									'adto13nasferias': '-',
									'situacao': '-',
									children: children,
									icons: {
										iconLeaf: 'none',
										iconCollapse: 'fas fa-angle-down',
										iconExpand: 'fas fa-angle-right'
									}
								},
							);
						}
					}
				}
			}
		}
	}

	possuiFeriasNaoGozadas(periodoAquisitivo: IPeriodoAquisitivo): boolean {
		if (periodoAquisitivo.hasOwnProperty('solicitacoes')) {
			return periodoAquisitivo.solicitacoes.some((solicitacao: any) => {
				return moment(solicitacao.datafimgozo).isSameOrAfter(moment());
			});
		}
	}

	existemOutrasMarcacoes(): boolean {
		if (this.tree_data.length > 0 && this.tree_data[0].children.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	salvar(): void {

		// se a data inicio gozo for menor que dataMinimaGozo, mostrar toaster e não salvar
		if (this.entity.trabalhadorobj!.tipo !== TipoColaboradorEnum.ESTAGIARIO && moment(this.entity.datainiciogozo, 'YYYY-MM-DD').isBefore(this.dataMinimaInicioGozo)) {
			this.NewToaster.pop({
				type: 'error',
				title: 'Início de gozo inválido',
				body: 'A data de início de gozo não pode ser inferior a data mínima de início de gozo.',
				timeout: 5000
			});
			return;
		}

		if (this.form.$valid && !this.entity.$$__submitting) {
			this.busy = true;
			this.entity.trabalhador = this.entity.trabalhadorobj?.trabalhador!;
			delete this.entity.dataaviso;

			this.entityService.save(this.entity, {
                empresa: this.entity.empresaobj.empresa,
                estabelecimento: this.entity.estabelecimentoobj.estabelecimento
            });

		} else {
			this.NewToaster.pop({
				type: 'error',
				title: 'Ocorreu um erro ao criar a Marcação de Férias!',
				body: 'Por favor, verifique os campos destacados em vermelho.'
			});
		}
	}

	goToShow(): void {
		this.busy = true;
		this.$state.go('ferias_marcacoes_show',
			angular.extend({}, {
				'solicitacao': this.entity.solicitacao,
			})
		);
	}

	goToNew(): void {
		this.$state.go('ferias_marcacoes_new', {
			trabalhador: {
				...this.entity.trabalhadorobj,
				empresaobj: this.entity.empresaobj,
				estabelecimentoobj: this.entity.estabelecimentoobj,
			}
		});
	}

	onSolicitacoesLoaded(): void {
		this.$scope.$on('solicitacoes_agrupadas_loaded', (event: any, args: any) => {
			this.buscouSolicitacoes = true;
			this.montaTree(args.response);
		});
	}

	onPeriodosLoaded(): void {
		this.$scope.$on('periodos_aquisitivos_loaded', (event: any, args: any) => {
			this.buscouPeriodos = true;
			this.montaTree(args.response);
		});
	}

	onSubmitSuccess(): void {
		this.$scope.$on('meurh_solicitacoesferias_submitted', (event: any, args: any) => {
			if (this.entityService.constructors.situacao === -1) {
				this.entityService.constructors.id = this.entity.solicitacao;
			}

			this.NewToaster.pop({
				type: 'success',
				title: 'A Solicitação de Marcação de Férias foi editada com sucesso!'
			});

			this.$state.go('ferias_marcacoes_show', angular.extend({}, {
				'solicitacao': args.entity.solicitacao
			}));

		});
	}

	onSubmitError(): void {
		this.$scope.$on('meurh_solicitacoesferias_submit_error', (event: angular.IAngularEvent, args: any) => {
			if (args.response.status === 409) {
				if (confirm(args.response.data.message)) {
					this.entity[''] = args.response.data.entity[''];
					delete this.entity.dataaviso;
					this.entityService.save(this.entity, {
						empresa: this.entity.empresaobj.empresa,
						estabelecimento: this.entity.estabelecimentoobj.estabelecimento
					});
				}
			} else {
				this.busy = false;

				if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
					if (args.response.data.message === 'Validation Failed') {
						let message: string = '';
						if (args.response.data.errors.errors) {
							message = args.response.data.errors.errors[0];
						} else if (args.response.data.errors.children) {
							let children = args.response.data.errors.children;
							let childrenMsg: string = '';
							for (let child in children) {
								if (children[child].errors !== undefined) {
									for (let error in children[child].errors) {
										if (children[child].errors.hasOwnProperty(error)) {
											childrenMsg += '<li>' + children[child].errors[error] + '</li>';
										}
									}
								}
							}
							message = 'Os seguintes itens precisam ser alterados: <ul>' + childrenMsg + '</ul>';
						}

						this.NewToaster.pop({
							type: 'error',
							title: 'Erro de Validação',
							body: message,
							bodyOutputType: 'trustedHtml'
						});
					} else {
						this.NewToaster.pop({
							type: 'error',
							title: args.response.data.message
						});
					}
				} else {
					this.NewToaster.pop({
						type: 'error',
						title: 'Ocorreu um erro ao editar a Solicitação de Férias!',
						body: 'Por favor, verifique os campos em vermelho.'
					});
				}
			}
		});
	}
}
