import * as html2pdf from "html2pdf.js";
angular.module('main').service('RelatoriosService', RelatoriosService);

RelatoriosService.$inject = ["$q", "$http", "nsjRouting", "NewToaster", '$uibModal',];

function RelatoriosService($q, $http, nsjRouting, NewToaster,$uibModal){
    var showToaster = function(type, title){
        NewToaster.pop({
            type:type,
            title:title
        });
    }

    var self = {
        busy: false,
        baixarRelatorio :  function(rota, constructors, callback = null, useToaster = true){
            self.busy = true;
            if(useToaster){
                showToaster('info', 'Buscando informações...');
            }
            $http.get(nsjRouting.generate(rota, constructors,true),'',  { responseType: 'arraybuffer' })
                .then(function(response){
                    var a = document.createElement("html");
                    a.setAttribute("id", "htmlpdf");
                    a.innerHTML = response.data;
                    //document.body.appendChild(a);
                    var element = document.getElementById('htmlpdf');
                    var opt = {
                        margin:       1,
                        filename:     'arquivo.pdf',
                        image:        { type: 'jpeg', quality: 0.98 },
                        html2canvas:  { scale: 2 },
                        jsPDF:        { unit: 'in', orientation: 'portrait' }
                    };
                    html2pdf().set(opt).from(a).save();
                    a.remove();
                })
                .catch(function(error){
                    showToaster('error', error.data.message);
                }).finally(function(){
                    if(typeof callback === "function") {
                        callback();
                    }
                    self.busy = false;
                });
        },
        baixarRelatorioPost :  function(rota, constructors, callback = null, useToaster = true){
            self.busy = true;
            if(useToaster){
                showToaster('info', 'Buscando informações...');
            }
            $http.post(nsjRouting.generate(rota, constructors,true),constructors,  '')
                .then(function(response){
                    var a = document.createElement("html");
                    a.setAttribute("id", "htmlpdf");
                    a.innerHTML = response.data;
                    //document.body.appendChild(a);
                    var element = document.getElementById('htmlpdf');
                    var opt = {
                        margin:       1,
                        filename:     'arquivo.pdf',
                        image:        { type: 'jpeg', quality: 0.98 },
                        html2canvas:  { scale: 2 },
                        jsPDF:        { unit: 'in', orientation: 'portrait' }
                    };
                    html2pdf().set(opt).from(a).save();
                    a.remove();
                })
                .catch(function(error){
                    showToaster('error', error.data.message);
                }).finally(function(){
                    if(typeof callback === "function") {
                        callback();
                    }
                    self.busy = false;
                });
        },
        baixarRelatorioFopPost: function(route, config, callback = null) {
            self.busy = true;
            if(config.useToaster) {
                showToaster('info', 'Buscando informações...');
            }
            $http.post(nsjRouting.generate(route, config.constructors, true), config.constructors, { responseType: 'blob' })
                .then(function(response) {
                    let file = new Blob([response.data]);
                    let fileURL = URL.createObjectURL(file);
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = fileURL;
                    a.download = config.fileName ? config.fileName : 'documento.pdf';
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                })
                .catch(function(error) {
                    showToaster('error', error.data.message);
                })
                .finally(function() {
                    if(typeof callback === "function") {
                        callback();
                    }
                    self.busy = false;
                });
        },
        baixarRelatorioFop:  function(rota, constructors, callback = null, useToaster = true){
            self.busy = true;
            if(useToaster){
                showToaster('info', 'Buscando informações...');
            }
            $http.get(nsjRouting.generate(rota, constructors,true),  { responseType: 'blob' })
            .then(function (response) { 
                let file = new Blob([response.data], { type: 'application/octet-stream' });
                let fileURL = URL.createObjectURL(file);
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style.display = "none";
                a.href = fileURL;
                a.download = 'documento.pdf';
                a.click();
                window.URL.revokeObjectURL(fileURL);
            }).catch(function(error){
                showToaster('error', error.data.message);
            }).finally(function(){
                if(typeof callback === "function") {
                    callback();
                }
                self.busy = false;
            });
        },
        abrirRelatorioFop:  function(rota, constructors, callback = null, useToaster = true){
            self.busy = true;
            if(useToaster){
                showToaster('info', 'Buscando informações...');
            }
            $http.get(nsjRouting.generate(rota, constructors,true),  { responseType: 'blob' })
            .then(function (response) { 
                let file = new Blob([response.data], { type: 'application/pdf' });
                let fileURL = URL.createObjectURL(file);
                let uibModalInst = $uibModal.open({
                    animation: true,
                    keyboard: true,
                    backdrop: true,
                    template:
                        `<a id="closeModal" class="pull-right" style="margin-top: -30px;color: lightgray;cursor: pointer;" ng-click="ctrlModal.close()">
                            <i class="fas fa-times"></i>
                        </a>` +
                        '<embed src="' +
                        fileURL +
                        '" width="100%" height="100%" ng-init="ctrlModal.setStyle()"/>',
                    controller: function () {
                        let selfModal = this;
                        selfModal.setStyle = function () {
                            (document.getElementsByClassName('modal-dialog')[0]).style.width =
                                '90%';
                            (document.getElementsByClassName('modal-dialog')[0]).style.height =
                                '90%';
                            (document.getElementsByClassName('modal-content')[0]).style.padding =
                                '40px 20px 20px 20px';
                            (document.getElementsByClassName('modal-content')[0]).style.height =
                                '100%';
                        }
                        selfModal.close = function() {
                            uibModalInst.close(false)
                        }
                    },
                    controllerAs: 'ctrlModal'
                })
                uibModalInst.result.then(
                    function() {
                        uibModalInst = undefined
                    },
                    function() {
                        uibModalInst = undefined
                    }
                )
            }).catch(function(error){
                showToaster('error', error.data.message);
            }).finally(function(){
                if(typeof callback === "function") {
                    callback();
                }
                self.busy = false;
            });
        },
        abrirRelatorio:  function(rota, constructors, callback = null, useToaster = false){
            self.busy = true;
            if(useToaster){
                showToaster('info', 'Buscando informações...');
            }
            $http.get(nsjRouting.generate(rota, constructors,true),  { responseType: 'arraybuffer' })
            .then(function (response) { 
                let mimeType = response.headers('Content-Type');
                var file = new Blob([response.data], {type:mimeType});
                let fileURL = URL.createObjectURL(file);
                let uibModalInst = $uibModal.open({
                    animation: true,
                    keyboard: true,
                    backdrop: true,
                    template:
                        `<a id="closeModal" class="pull-right" style="margin-top: -30px;color: lightgray;cursor: pointer;" ng-click="ctrlModal.close()">
                            <i class="fas fa-times"></i>
                        </a>` +
                        '<embed src="' +
                        fileURL +
                        '" width="100%" height="100%" ng-init="ctrlModal.setStyle()"/>',
                    controller: function () {
                        let selfModal = this;
                        selfModal.setStyle = function () {
                            (document.getElementsByClassName('modal-dialog')[0]).style.width =
                                '90%';
                            (document.getElementsByClassName('modal-dialog')[0]).style.height =
                                '90%';
                            (document.getElementsByClassName('modal-content')[0]).style.padding =
                                '40px 20px 20px 20px';
                            (document.getElementsByClassName('modal-content')[0]).style.height =
                                '100%';
                        }
                        selfModal.close = function() {
                            uibModalInst.close(false)
                        }
                    },
                    controllerAs: 'ctrlModal'
                })
                uibModalInst.result.then(
                    function() {
                        uibModalInst = undefined
                    },
                    function() {
                        uibModalInst = undefined
                    }
                )
                showToaster('success', 'Arquivo gerado com sucesso!');
            }).catch(function(error){
                showToaster('error', error.data.message);
            }).finally(function(){
                if(typeof callback === "function") {
                    callback();
                }
                self.busy = false;
            });
        },
        listarDocumentosFop: function (entidade) {
            return $q((resolve, reject) => {
                $http
                .get(nsjRouting.generate('ns_documentosfop_indexagrupado', { documentoentidade_entidade: entidade }, true))
                .then((response) => {
                    resolve(response.data);
                })
                .catch((response) => {
                    reject(response);
                });
            });
        },
        baixarPlanilhaXlsx: function(rota, constructors, callback = null){
            self.busy = true;

            $http.get(nsjRouting.generate(rota, constructors, true),  { responseType: 'arraybuffer' })
            .then(function (response) {
                var file = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                let fileURL = URL.createObjectURL(file);

                // cria um link temporário para disparar o download
                var a = document.createElement('a');
                a.href = fileURL;

                a.download = 'planilhacontroledeferias.xlsx';

                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                // libera a URL criada
                URL.revokeObjectURL(fileURL);

                showToaster('success', 'Arquivo gerado com sucesso!');
            }).catch(function(error){
                showToaster('error', error.data.message);
            }).finally(function(){
                if(typeof callback === "function") {
                    callback();
                }
                self.busy = false;
            });
        },
    }
    return self;
}