declare var nsjGlobals: any;
import angular from 'angular';
import { ConfiguracoesPortariaModalService } from '../modal.portaria/configuracoes.portariamodal.service';

type PortariaEntity = {
    portaria?: string,
    tenant?: number,
    status: 0 | 1 | 2 | 3 | null,
    created_at?: string,
    created_by?: {
        nome: string,
        email: string
    },
    nome?: string,
    cpf?: string,
    lastupdate?: string,
    horaativacao?: string
};

export class ConfiguracoesIndexController {
    static $inject = ['$scope', 'ConfiguracoesService', 'NewToaster', '$rootScope', '$http', 'nsjRouting', '$q',
        '$uibModal', 'ApontamentosRubricasapontamento', 'BuscaAvancadaService', 'EventosService',
        '$state', '$stateParams', 'TiposDocumentosRequeridosService', 'PersonaTiposdocumentoscolaboradoresService', 'ModalConfirmService', 'ConfiguracoesPortariaModalService' ];

    public ckEditorConfig = {
        removePlugins: 'about, youtube'
    };
    public tiposdocumentosrequeridos: any = [];
    public tiposdocumentoscolaboradores: any = [];
    public fields: any = [];
    public solicitacoesLabels = [
        { 'label': 'Rescisão', 'index': 1 },
        { 'label': 'Adiantamento Avulso', 'index': 2 },
        { 'label': 'VT Adicional', 'index': 3 },
        { 'label': 'Alteração de VT', 'index': 4 },
        { 'label': 'Alteração de Dados Cadastrais', 'index': 5 },
        { 'label': 'Falta', 'index': 6 },
        { 'label': 'Férias', 'index': 7 },
        { 'label': 'Afastamento', 'index': 8 },
        { 'label': 'Promoções', 'index': 9 },
        { 'label': 'Créditos e Descontos', 'index': 10 },
        { 'label': 'Admissão Completa', 'index': 12 },
        { 'label': 'Admissão de estagiário', 'index': 15 },
        { 'label': 'Dependentes', 'index': 16 },
        { 'label': 'Alteração de dados de recebimento salarial', 'index': 20 },
        { 'label': 'Pedido especial', 'index': 25 },
    ];
    public historicoadiantamentoavulso: any = {};
    public salvandoOrdenacao: any = false;
    public docsObrigatoriosBusy: boolean = false;
    public documentosNome: any;
    public estabelecimentoSelecionado: any;
    public rubricasapontamento: any;
    public rubrica: null;
    public historicoadiantamentoavulsoIsBusy: boolean;
    public sortableOptions: any = {
        disabled: this.isLoadingRubricas(),
        animation: 150,
        handle: '.handle-wrapper',
    };

    public portariaEntity: PortariaEntity = {
        status: null,
    };
    public portariaBusy = false;
    public activeTab = this.$rootScope.soPossuiPonto() ? 9 : 1;

    public exibeMsgAntecedenciaFerias: boolean = false;
    public exibeBotaoConfigsGerais: boolean = true;
    public alterouAntecedenciaFerias: boolean = false;

    public prazoAvisoFimPeriodoExperienciaValido: boolean = true;

    public prazoAvisoVencimentoCNHValido: boolean = true;

    public prazoAvisoVencimentoOCValido: boolean = true;

    public arr_chaves_avisos: any = [
        'AVISO_IMINENCIA_VENCIMENTO_FERIAS',
        'AVISO_VENCIMENTO_FERIAS',
        'AVISO_IMINENCIA_DOBRA_FERIAS',
        'AVISO_FERIAS_DOBRADAS',
        'AVISO_FIM_PERIODO_EXPERIENCIA',
        'AVISO_APONTAMENTO',
        'AVISO_DOCUMENTOS_ADICIONADOS',
        'AVISO_FUNCIONARIO_CONTRATADO',
        'AVISO_FUNCIONARIO_DEMITIDO',
        'AVISO_AVISO_FERIAS',
        'AVISO_VT_ADICIONAL_APROVADO',
        'AVISO_VT_ADICIONAL_REJEITADO',
        'AVISO_ALTERACAOVT_APROVADA',
        'AVISO_ALTERACAOVT_REJEITADA',
        'AVISO_ADIANTAMENTO_AVULSO_APROVADO',
        'AVISO_ADIANTAMENTO_AVULSO_REJEITADO',
        'AVISO_VENCIMENTO_CNH',
        'AVISO_VENCIMENTO_OC'];

    public arr_chaves_prazos_avisos: any = [
        'PRAZO_AVISO_IMINENCIA_VENCIMENTO_FERIAS',
        'PRAZO_AVISO_IMINENCIA_DOBRA_FERIAS',
        'PRAZO_AVISO_FIM_PERIODO_EXPERIENCIA',
        'PRAZO_AVISO_APONTAMENTO',
        'PRAZO_AVISO_DOCUMENTOS_ADICIONADOS',
        'PRAZO_AVISO_FUNCIONARIO_CONTRATADO',
        'PRAZO_AVISO_FUNCIONARIO_DEMITIDO',
        'PRAZO_AVISO_AVISO_FERIAS',
        'PRAZO_AVISO_VT_ADICIONAL_APROVADO',
        'PRAZO_AVISO_VT_ADICIONAL_REJEITADO',
        'PRAZO_AVISO_ALTERACAOVT_APROVADA',
        'PRAZO_AVISO_ALTERACAOVT_REJEITADA',
        'PRAZO_AVISO_ADIANTAMENTO_AVULSO_APROVADO',
        'PRAZO_AVISO_ADIANTAMENTO_AVULSO_REJEITADO',
        'PRAZO_AVISO_VENCIMENTO_CNH',
        'PRAZO_AVISO_VENCIMENTO_OC'];

    public arr_chaves_not_email: Array<string> = ['NOTIFICACAO_DOBRA_FERIAS'];
    public arr_chaves_prazos_not_email: Array<string> = ['PRAZO_NOTIFICACAO_DOBRA_FERIAS'];
    public arr_config_json: Array<string> = ['ATRASO_RESCISAO'];

    public arr_chaves_string_to_int: Array<string> = [
        'QTD_DIAS_ANTECEDENCIA_FERIAS_MRH',
        'QTD_DIAS_ANTECEDENCIA_FERIAS_MT',
        'PRAZO_AVISO_FIM_PERIODO_EXPERIENCIA',
        'PRAZO_AVISO_VENCIMENTO_OC',
        'PRAZO_AVISO_VENCIMENTO_CNH'];

    public configuracoes: any = {
        'TITULO_DASHBOARD': { 'chave': 'TITULO_DASHBOARD', 'sistema': 'MEURH' },
        'MENSAGEM_DASHBOARD': { 'chave': 'MENSAGEM_DASHBOARD', 'sistema': 'MEURH' },
        'PERSONALIZACAO': { 'chave': 'PERSONALIZACAO', 'sistema': 'MEURH' },
        'DATA_LIBERACAO_CONTRACHEQUE': { 'chave': 'DATA_LIBERACAO_CONTRACHEQUE', 'sistema': 'MEURH' },
        'ATRASO_RESCISAO': { 'chave': 'ATRASO_RESCISAO', 'sistema': 'MEURH' },
        'ATRASO_ADMISSAO': { 'chave': 'ATRASO_ADMISSAO', 'sistema': 'MEURH' },
        'ADIANTAMENTO_13_FERIAS': { 'chave': 'ADIANTAMENTO_13_FERIAS', 'sistema': 'MEURH' },
        'AVISO_IMINENCIA_VENCIMENTO_FERIAS': { 'chave': 'AVISO_IMINENCIA_VENCIMENTO_FERIAS', 'sistema': 'MEURH' },
        'PRAZO_AVISO_IMINENCIA_VENCIMENTO_FERIAS': { 'chave': 'PRAZO_AVISO_IMINENCIA_VENCIMENTO_FERIAS', 'sistema': 'MEURH' },
        'AVISO_VENCIMENTO_FERIAS': { 'chave': 'AVISO_VENCIMENTO_FERIAS', 'sistema': 'MEURH' },
        'AVISO_IMINENCIA_DOBRA_FERIAS': { 'chave': 'AVISO_IMINENCIA_DOBRA_FERIAS', 'sistema': 'MEURH' },
        'PRAZO_AVISO_IMINENCIA_DOBRA_FERIAS': { 'chave': 'PRAZO_AVISO_IMINENCIA_DOBRA_FERIAS', 'sistema': 'MEURH' },
        'AVISO_FERIAS_DOBRADAS': { 'chave': 'AVISO_FERIAS_DOBRADAS', 'sistema': 'MEURH' },
        'AVISO_FIM_PERIODO_EXPERIENCIA': { 'chave': 'AVISO_FIM_PERIODO_EXPERIENCIA', 'sistema': 'MEURH' },
        'PRAZO_AVISO_FIM_PERIODO_EXPERIENCIA': { 'chave': 'PRAZO_AVISO_FIM_PERIODO_EXPERIENCIA', 'sistema': 'MEURH' },
        'AVISO_APONTAMENTO': { 'chave': 'AVISO_APONTAMENTO', 'sistema': 'MEURH' },
        'PRAZO_AVISO_APONTAMENTO': { 'chave': 'PRAZO_AVISO_APONTAMENTO', 'sistema': 'MEURH' },
        'AVISO_DOCUMENTOS_ADICIONADOS': { 'chave': 'AVISO_DOCUMENTOS_ADICIONADOS', 'sistema': 'MEURH' },
        'PRAZO_AVISO_DOCUMENTOS_ADICIONADOS': { 'chave': 'PRAZO_AVISO_DOCUMENTOS_ADICIONADOS', 'sistema': 'MEURH' },
        'AVISO_FUNCIONARIO_CONTRATADO': { 'chave': 'AVISO_FUNCIONARIO_CONTRATADO', 'sistema': 'MEURH' },
        'PRAZO_AVISO_FUNCIONARIO_CONTRATADO': { 'chave': 'PRAZO_AVISO_FUNCIONARIO_CONTRATADO', 'sistema': 'MEURH' },
        'AVISO_FUNCIONARIO_DEMITIDO': { 'chave': 'AVISO_FUNCIONARIO_DEMITIDO', 'sistema': 'MEURH' },
        'PRAZO_AVISO_FUNCIONARIO_DEMITIDO': { 'chave': 'PRAZO_AVISO_FUNCIONARIO_DEMITIDO', 'sistema': 'MEURH' },
        'AVISO_AVISO_FERIAS': { 'chave': 'AVISO_AVISO_FERIAS', 'sistema': 'MEURH' },
        'PRAZO_AVISO_AVISO_FERIAS': { 'chave': 'PRAZO_AVISO_AVISO_FERIAS', 'sistema': 'MEURH' },
        'AVISO_VT_ADICIONAL_APROVADO': { 'chave': 'AVISO_VT_ADICIONAL_APROVADO', 'sistema': 'MEURH' },
        'PRAZO_AVISO_VT_ADICIONAL_APROVADO': { 'chave': 'PRAZO_AVISO_VT_ADICIONAL_APROVADO', 'sistema': 'MEURH' },
        'AVISO_VT_ADICIONAL_REJEITADO': { 'chave': 'AVISO_VT_ADICIONAL_REJEITADO', 'sistema': 'MEURH' },
        'PRAZO_AVISO_VT_ADICIONAL_REJEITADO': { 'chave': 'PRAZO_AVISO_VT_ADICIONAL_REJEITADO', 'sistema': 'MEURH' },
        'AVISO_ALTERACAOVT_APROVADA': { 'chave': 'AVISO_ALTERACAOVT_APROVADA', 'sistema': 'MEURH' },
        'PRAZO_AVISO_ALTERACAOVT_APROVADA': { 'chave': 'PRAZO_AVISO_ALTERACAOVT_APROVADA', 'sistema': 'MEURH' },
        'AVISO_ALTERACAOVT_REJEITADA': { 'chave': 'AVISO_ALTERACAOVT_REJEITADA', 'sistema': 'MEURH' },
        'PRAZO_AVISO_ALTERACAOVT_REJEITADA': { 'chave': 'PRAZO_AVISO_ALTERACAOVT_REJEITADA', 'sistema': 'MEURH' },
        'AVISO_ADIANTAMENTO_AVULSO_APROVADO': { 'chave': 'AVISO_ADIANTAMENTO_AVULSO_APROVADO', 'sistema': 'MEURH' },
        'PRAZO_AVISO_ADIANTAMENTO_AVULSO_APROVADO': { 'chave': 'PRAZO_AVISO_ADIANTAMENTO_AVULSO_APROVADO', 'sistema': 'MEURH' },
        'AVISO_ADIANTAMENTO_AVULSO_REJEITADO': { 'chave': 'AVISO_ADIANTAMENTO_AVULSO_REJEITADO', 'sistema': 'MEURH' },
        'PRAZO_AVISO_ADIANTAMENTO_AVULSO_REJEITADO': { 'chave': 'PRAZO_AVISO_ADIANTAMENTO_AVULSO_REJEITADO', 'sistema': 'MEURH' },
        'COMUNICADO_DASHBOARD': { 'chave': 'COMUNICADO_DASHBOARD', 'sistema': 'MEURH' },
        'FECHAMENTO_FOLHA': { 'chave': 'FECHAMENTO_FOLHA', 'sistema': 'MEURH' },
        'FOLHA_FECHA_PROXIMO_MES': { 'chave': 'FOLHA_FECHA_PROXIMO_MES', 'sistema': 'MEURH' },
        'FECHAMENTO_ADIANTAMENTO': { 'chave': 'FECHAMENTO_ADIANTAMENTO', 'sistema': 'MEURH' },
        'PERMITE_ENVIO_ADIANTAMENTOS': { 'chave': 'PERMITE_ENVIO_ADIANTAMENTOS', 'sistema': 'MEURH' },
        'MENSAGEM_APONTAMENTO': { 'chave': 'MENSAGEM_APONTAMENTO', 'sistema': 'MEURH' },
        'BLOQUEAR_MARCACAO_DOBRA_FERIAS': { 'chave': 'BLOQUEAR_MARCACAO_DOBRA_FERIAS', 'sistema': 'MEURH' },
        'NOTIFICACAO_DOBRA_FERIAS': { 'chave': 'NOTIFICACAO_DOBRA_FERIAS', 'sistema': 'MEURH' },
        'PRAZO_NOTIFICACAO_DOBRA_FERIAS': { 'chave': 'PRAZO_NOTIFICACAO_DOBRA_FERIAS', 'sistema': 'MEURH' },
        'ASSINATURA_DIGITAL_HABILITADA': { 'chave': 'ASSINATURA_DIGITAL_HABILITADA', 'sistema': 'MEURH' },
        'ASSINATURA_DIGITAL_FORMA_AUTENTICACAO': { 'chave': 'ASSINATURA_DIGITAL_FORMA_AUTENTICACAO', 'sistema': 'MEURH' },
        'ASSINATURA_DIGITAL_MANUAL': { 'chave': 'ASSINATURA_DIGITAL_MANUAL', 'sistema': 'MEURH' },
        'ASSINATURA_DIGITAL_ADD_PAGINA_FOTO': { 'chave': 'ASSINATURA_DIGITAL_ADD_PAGINA_FOTO', 'sistema': 'MEURH' },
        'DESABILITAR_ADMISSAO_PRELIMINAR': { 'chave': 'DESABILITAR_ADMISSAO_PRELIMINAR', 'sistema': 'MEURH' },
        'QTD_DIAS_ANTECEDENCIA_FERIAS_MRH': { 'chave': 'QTD_DIAS_ANTECEDENCIA_FERIAS_MRH', 'sistema': 'MEURH' },
        'QTD_DIAS_ANTECEDENCIA_FERIAS_MT': { 'chave': 'QTD_DIAS_ANTECEDENCIA_FERIAS_MT', 'sistema': 'MEURH' },
        'AVISO_VENCIMENTO_CNH': { 'chave': 'AVISO_VENCIMENTO_CNH', 'sistema': 'MEURH' },
        'AVISO_VENCIMENTO_OC': { 'chave': 'AVISO_VENCIMENTO_OC', 'sistema': 'MEURH' },
        'PRAZO_AVISO_VENCIMENTO_CNH': { 'chave': 'PRAZO_AVISO_VENCIMENTO_CNH', 'sistema': 'MEURH' },
        'PRAZO_AVISO_VENCIMENTO_OC': { 'chave': 'PRAZO_AVISO_VENCIMENTO_OC', 'sistema': 'MEURH' },
    };

    constructor(
        public $scope: any,
        public ConfiguracoesService: any,
        public NewToaster: any,
        public $rootScope: any,
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $q: any,
        public $uibModal: any,
        public ApontamentosRubricasapontamento: any,
        public BuscaAvancadaService: any,
        public Eventos: any,
        public $state: any,
        public $stateParams: any,
        public TiposDocumentosRequeridosService: any,
        public PersonaTiposdocumentoscolaboradoresService: any,
        public ModalConfirmService: any,
        public ConfiguracoesPortariaModalService: ConfiguracoesPortariaModalService,
    ) {

        if (
            !$rootScope.isAdmin() ||
            ($rootScope.soPossuiPonto() && !$rootScope.liberadoTela('LIBERAR_ATIVACAO_PORTARIA_PONTO'))
        ) {
            $state.go('dp', $stateParams);
        }

        if ($rootScope.liberadoTela('LIBERAR_ATIVACAO_PORTARIA_PONTO')) {
            this.getPortariaEntity();
        }

        $scope.finishedLoadingElementsTable = {
            documentoscolaboradores: false,
            tiposdocumentosrequeridos: false,
        };

        this.solicitacoesLabels.sort((solicitacaoA, solicitacaoB) => {
            if (solicitacaoA.label < solicitacaoB.label) {
                return -1;
            }
            if (solicitacaoA.label > solicitacaoB.label) {
                return 1;
            }
            return 0;
        });

        this.estabelecimentoSelecionado = nsjGlobals.a.estabelecimentosInfos.filter((estabelecimento: any) => {
            return estabelecimento.id === nsjGlobals.a.estabelecimento;
        })[0];

        // this.configuracoes = ConfiguracoesService.reload();


        $scope.$on('configuracoes_submitted', (event: any, args: any) => {
            NewToaster.pop({
                type: 'success',
                title: 'Sucesso',
                body: 'As configurações foram salvas com sucesso!'
            });
            ConfiguracoesService.reload();
            this.historicoadiantamentoavulsoIsBusy = false;
            this.alterouAntecedenciaFerias = false;
        });

        $scope.$on('configuracoes_submit_error', (event: any, args: any) => {
            if (args.response.data) {
                NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            }
        });

        $scope.$on('apontamentos_rubricasapontamento_submitted', (event: any, args: any) => {
            NewToaster.pop({
                type: 'success',
                title: 'Rubrica configurada com sucesso!'
            });
            ApontamentosRubricasapontamento.reload();
            this.rubrica = null;
        });

        // @todo Melhorar esse trecho caso não tenha essas configurações
        $rootScope.$on('configuracoes_list_finished', (event: any, args: any) => {
            this.historicoadiantamentoavulso.historicoadiantamentoavulso = this.configuracoes['SALARIOSOBDEMANDA_ADIANTAMENTO_AVULSO'] ? this.configuracoes['SALARIOSOBDEMANDA_ADIANTAMENTO_AVULSO'].valor : null;
            if (this.configuracoes['MEUTRABALHO_CALCSALARIOMES_PERCENTUAL']) {
                this.configuracoes['MEUTRABALHO_CALCSALARIOMES_PERCENTUAL'].valor = parseInt(this.configuracoes['MEUTRABALHO_CALCSALARIOMES_PERCENTUAL'].valor, 10);
            }
            if (this.configuracoes['MEUTRABALHO_CALCSALARIOMES_QTDRECIBOS']) {
                this.configuracoes['MEUTRABALHO_CALCSALARIOMES_QTDRECIBOS'].valor = parseInt(this.configuracoes['MEUTRABALHO_CALCSALARIOMES_QTDRECIBOS'].valor, 10);
            }
            if (this.configuracoes['SOLICITACAO_SALARIO_SOBDEMANDA_VALOR_MIN']) {
                this.configuracoes['SOLICITACAO_SALARIO_SOBDEMANDA_VALOR_MIN'].valor = parseFloat(this.configuracoes['SOLICITACAO_SALARIO_SOBDEMANDA_VALOR_MIN'].valor);
            }
        });

        $scope.$on('apontamentos_rubricasapontamento_submit_error', (event: any, args: any) => {
            NewToaster.pop({
                type: 'error',
                title: args.response.data.message
            });
        });

        $scope.$on('apontamentos_rubricasapontamento_deleted', (event: any, args: any) => {
            NewToaster.pop({
                type: 'success',
                title: 'Sucesso ao excluir rubrica de apontamento!'
            });
            ApontamentosRubricasapontamento.reload();
        });

        $scope.$on('apontamentos_rubricasapontamento_delete_error', (event: any, args: any) => {
            NewToaster.pop({
                type: 'error',
                title: args.response.data.message
            });
        });
    }

    async $onInit() {
        this.PersonaTiposdocumentoscolaboradoresService.loadParams.finished = false;
        this.TiposDocumentosRequeridosService.loadParams.finished = false;

        this.tiposdocumentoscolaboradores = [];
        this.tiposdocumentosrequeridos = [];

        this.fields = [
            {
                value: 'solicitacao',
                label: '',
                type: 'string',
                style: 'title',
                hideLabel: true,
                copy: '',
                rowspanNaCelula: true,
                customCellClass: 'scd-child-shadow',
            }
        ];
        this.PersonaTiposdocumentoscolaboradoresService.entities = [];
        this.tiposdocumentoscolaboradores = await this.PersonaTiposdocumentoscolaboradoresService.load();
        this.$scope.finishedLoadingElementsTable.documentoscolaboradores = true;

        this.tiposdocumentosrequeridos = await this.TiposDocumentosRequeridosService.load_configuracoes();
        this.$scope.finishedLoadingElementsTable.tiposdocumentosrequeridos = true;

        this.$scope.$on('meurh_tiposdocumentosrequeridos_submitted', () => {
            this.docsObrigatoriosBusy = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'Sucesso',
                body: 'As configurações foram salvas com sucesso!'
            });
        });

        this.$scope.$watch('finishedLoadingElementsTable', () => {
            if (this.$scope.finishedLoadingElementsTable.documentoscolaboradores && this.$scope.finishedLoadingElementsTable.tiposdocumentosrequeridos) {

                this.tiposdocumentoscolaboradores.forEach((tipodocumentocolaborador: any) => {
                    let guid = tipodocumentocolaborador.tipodocumentocolaborador;
                    guid = guid.replaceAll('-', '_');
                    this.fields.push(
                        {

                            value: 'obrigatorio_' + guid,
                            label: tipodocumentocolaborador.descricao,
                            type: 'string',
                            style: 'title',
                            copy: '',
                            customCollumnHtml: () => {
                                let someHtmlVar = `
                                <select ng-model='::tableRowEntity.obrigatorio_` + guid + `' class='form-control'>
                                <option value=''>Desativado</option>
                                <option value='false'>Opcional</option>
                                <option value='true'>Obrigatório</option>
                                </select>`;
                                return someHtmlVar;
                            }
                        }
                    );
                });

                this.documentosNome = [];
                let documentoNome = {};
                this.solicitacoesLabels.forEach((solicitacaolabel) => {
                    documentoNome = {
                        'solicitacao': solicitacaolabel.label,
                        'solicitacaoId': solicitacaolabel.index
                    };

                    this.tiposdocumentoscolaboradores.forEach((tipodocumentocolaborador) => {
                        let guid = tipodocumentocolaborador.tipodocumentocolaborador;
                        guid = guid.replaceAll('-', '_');
                        let tipodocumentorequerido = this.tiposdocumentosrequeridos.filter((elem: any) => {
                            return elem.tipodocumentocolaborador === tipodocumentocolaborador.tipodocumentocolaborador && elem.tiposolicitacao === solicitacaolabel.index;
                        });

                        documentoNome['obrigatorio_' + guid] = tipodocumentorequerido.length ? tipodocumentorequerido[0].obrigatorio.toString() : '';
                    });

                    this.documentosNome.push(documentoNome);
                });
            }
        }, true);

        this.ConfiguracoesService.reload();

        this.$scope.$on('configuracoes_list_finished', (event: any, args: any) => {
            Object.keys(this.configuracoes).forEach(configuracao => {
                if (this.arr_config_json.includes(configuracao)) {
                    this.configuracoes[configuracao].valor = JSON.parse(args[this.configuracoes[configuracao].chave].valor);
                    this.configuracoes[configuracao].configuracao = args[this.configuracoes[configuracao].chave].configuracao;
                } else if (args[this.configuracoes[configuracao].chave]) {
                    this.configuracoes[configuracao].valor = args[this.configuracoes[configuracao].chave].valor;
                    this.configuracoes[configuracao].configuracao = args[this.configuracoes[configuracao].chave].configuracao;
                } else {
                    this.configuracoes[configuracao].valor = null;
                }

                // valor é retornado como string, é necessário fazer o parse para inteiro
                if (this.arr_chaves_string_to_int.includes(configuracao) && this.configuracoes[configuracao].valor) {
                    this.configuracoes[configuracao].valor = parseInt(this.configuracoes[configuracao].valor, 10);
                    // validação dos valores carregados
                    this.ValidaPrazos(this.configuracoes[configuracao]);
                }
            });
        });
    }

    ValidaPrazos(prazo: any) {
        if (prazo.chave === 'PRAZO_AVISO_FIM_PERIODO_EXPERIENCIA') {
            return this.validaPrazoAvisoFimPeriodoExperiencia(prazo.valor);
        }

        if (prazo.chave === 'PRAZO_AVISO_VENCIMENTO_OC') {
            return this.validaPrazoAvisoVencimentoOC(prazo.valor);
        }

        if (prazo.chave === 'PRAZO_AVISO_VENCIMENTO_CNH') {
            return this.validaPrazoAvisoVencimentoCNH(prazo.valor);
        }

        return null;
    }

    ApontamentoValido() {
        let dias: any = document.getElementById('diaFechamento');
        let meses: any = document.getElementById('mesFechamento');

        let diaFechamento: any = dias.options[dias.selectedIndex].value;
        let mesFechamento: any = meses.options[meses.selectedIndex].value;

        let diaPagamento: any = this.$rootScope.configuracoes['PAGAMENTO_DIA_FOLHA'];
        let mesPagamento: any = this.$rootScope.configuracoes['PAGAMENTO_PROXIMO_MES'];

        if (diaFechamento >= diaPagamento && ((mesFechamento === 0 && !mesPagamento) || (mesFechamento === 1 && mesPagamento))) {
            return false;
        } else if ((mesFechamento === 1 && !mesPagamento)) {
            return false;
        } else {
            return true;
        }
    }

    salvarDocsObrigatorios() {
        this.docsObrigatoriosBusy = true;
        let documentosRequeridos: any = [];
        this.documentosNome.forEach((item: any) => {
            for (let property in item) {
                if (property.indexOf('obrigatorio_') >= 0) {
                    if (item[property] !== '') {
                        let tipodocumentocolab = property.substr(12).split('_').join('-');

                        let documentoRequerido: any = {
                            'tiposolicitacao': item['solicitacaoId'],
                            'tipodocumentocolaborador': {
                                'tipodocumentocolaborador': tipodocumentocolab
                            },
                            'obrigatorio': item[property]
                        };

                        documentosRequeridos.push(documentoRequerido);
                    }
                }
            }
        });

        this.TiposDocumentosRequeridosService.save_configuracoes(documentosRequeridos);
    }

    carregarRubricas() {
        this.ApontamentosRubricasapontamento.constructors.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.rubricasapontamento = this.ApontamentosRubricasapontamento.reload();
    }

    isBusy() {
        return this.ConfiguracoesService.loadParams.busy || this.ApontamentosRubricasapontamento.loadParams.isBusy;
    }

    isBusySave() {
        return this.ConfiguracoesService.loadParams.busySave || this.salvandoOrdenacao;
    }

    salvarConfiguracoes(chaves: any) {
        let configs: any = [];
        for (let i = 0; i < chaves.length; i++) {
            Object.keys(this.configuracoes).forEach(configuracao => {
                if (this.configuracoes[configuracao].chave === chaves[i]) {
                    if (this.arr_config_json.includes(chaves[i])) {
                        this.configuracoes[configuracao].valor = JSON.stringify(this.configuracoes[configuracao].valor);
                    }
                    configs.push(this.configuracoes[configuracao]);
                }
            });
        }
        this.ConfiguracoesService.save(configs);
        for (let i = 0; i < chaves.length; i++) {
            Object.keys(this.configuracoes).forEach(configuracao => {
                if (this.configuracoes[configuracao].chave === chaves[i]) {
                    if (this.$rootScope.configuracoes.hasOwnProperty(chaves[i])) {
                        this.$rootScope.configuracoes[chaves[i]] = this.configuracoes[configuracao].valor;
                    }
                }
            });
        }
    }

    salvarConfigSolicitacoes() {
        this.historicoadiantamentoavulsoIsBusy = true;

        // this.configuracoes['SALARIOSOBDEMANDA_ADIANTAMENTO_AVULSO'].valor = typeof this.historicoadiantamentoavulso.historicoadiantamentoavulso != undefined ? this.historicoadiantamentoavulso.historicoadiantamentoavulso : ';

        let configs = [
            'MEUTRABALHO_CALCSALARIOMES_TIPO',
            'MEUTRABALHO_CALCSALARIOMES_QTDRECIBOS',
            'MEUTRABALHO_CALCSALARIOMES_PERCENTUAL',
            'SOLICITACAO_SALARIO_SOBDEMANDA_VALOR_MIN'
        ];

        // if (typeof this.historicoadiantamentoavulso.historicoadiantamentoavulso !== 'undefined') {
        //     configs.push('SALARIOSOBDEMANDA_ADIANTAMENTO_AVULSO');
        // }

        this.salvarConfiguracoes(configs);
    }

    salvarMensagemDashboard() {
        this.salvarConfiguracoes(['TITULO_DASHBOARD', 'MENSAGEM_DASHBOARD']);
    }

    salvarConfiguracoesGerais() {
        if (this.alterouAntecedenciaFerias && (this.configuracoes.QTD_DIAS_ANTECEDENCIA_FERIAS_MRH.valor < 30 || this.configuracoes.QTD_DIAS_ANTECEDENCIA_FERIAS_MT.valor < 30)) {
            let customText = {
                title: 'Marcação de férias inferior a 30 dias',
                confirmText: 'Ao salvar, você confirma a configuração que permite a marcação de férias com menos de 30 dias de antecedência, abaixo do período mínimo legal. Tem certeza que deseja salvar essa configuração?',
                closeButton: 'Cancelar',
                confirmButton: 'Salvar configuração'
            };

            let confirm = this.ModalConfirmService.open({}, 'configuracoesgerais', false, customText);
            confirm.result.then(() => {
                this.salvarConfiguracoes(['PERSONALIZACAO', 'DATA_LIBERACAO_CONTRACHEQUE', 'ATRASO_RESCISAO', 'ATRASO_ADMISSAO', 'ADIANTAMENTO_13_FERIAS', 'BLOQUEAR_MARCACAO_DOBRA_FERIAS', 'DESABILITAR_ADMISSAO_PRELIMINAR', 'QTD_DIAS_ANTECEDENCIA_FERIAS_MRH', 'QTD_DIAS_ANTECEDENCIA_FERIAS_MT']);
            }).catch(() => {
                return;
            });
        } else {
            this.salvarConfiguracoes(['PERSONALIZACAO', 'DATA_LIBERACAO_CONTRACHEQUE', 'ATRASO_RESCISAO', 'ATRASO_ADMISSAO', 'ADIANTAMENTO_13_FERIAS', 'BLOQUEAR_MARCACAO_DOBRA_FERIAS', 'DESABILITAR_ADMISSAO_PRELIMINAR', 'QTD_DIAS_ANTECEDENCIA_FERIAS_MRH', 'QTD_DIAS_ANTECEDENCIA_FERIAS_MT']);
        }
    }

    salvarConfiguracoesAvisosPrazos() {
        this.salvarConfiguracoes([].concat(this.arr_chaves_avisos, this.arr_chaves_prazos_avisos));
    }

    salvarNotificacoesPorEmail() {
        this.salvarConfiguracoes(([] as Array<string>).concat(this.arr_chaves_not_email, this.arr_chaves_prazos_not_email));
    }

    salvarAssinaturaDigital() {
        // definindo valor Default para Assinatura
        if (this.configuracoes['ASSINATURA_DIGITAL_HABILITADA']['valor'] == null) {
            this.configuracoes['ASSINATURA_DIGITAL_HABILITADA']['valor'] = false;
        }
        if (this.configuracoes['ASSINATURA_DIGITAL_FORMA_AUTENTICACAO']['valor'] == null) {
            this.configuracoes['ASSINATURA_DIGITAL_FORMA_AUTENTICACAO']['valor'] = '1';
        }
        if (this.configuracoes['ASSINATURA_DIGITAL_MANUAL']['valor'] == null) {
            this.configuracoes['ASSINATURA_DIGITAL_MANUAL']['valor'] = true;
        }
        if (this.configuracoes['ASSINATURA_DIGITAL_ADD_PAGINA_FOTO']['valor'] == null) {
            this.configuracoes['ASSINATURA_DIGITAL_ADD_PAGINA_FOTO']['valor'] = false;
        }
        this.salvarConfiguracoes([
            'ASSINATURA_DIGITAL_HABILITADA',
            'ASSINATURA_DIGITAL_FORMA_AUTENTICACAO',
            'ASSINATURA_DIGITAL_MANUAL',
            'ASSINATURA_DIGITAL_ADD_PAGINA_FOTO'
        ]);
    }

    salvarComunicado() {
        this.salvarConfiguracoes(['COMUNICADO_DASHBOARD']);
    }

    salvarConfiguracoesApontamento() {
        if (!this.ApontamentoValido()) {
            this.NewToaster.pop({
                type: 'error',
                title: 'A data de fechamento da folha não pode ser superior a data de pagamento da folha'
            });
        } else {
            this.salvarConfiguracoes(['FECHAMENTO_FOLHA', 'FOLHA_FECHA_PROXIMO_MES', 'FECHAMENTO_ADIANTAMENTO', 'PERMITE_ENVIO_ADIANTAMENTOS']);
        }
    }

    salvarMensagemApontamento() {
        this.salvarConfiguracoes(['MENSAGEM_APONTAMENTO']);
    }

    buscaAvancada() {
        this.Eventos.constructors.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.Eventos.constructors.apontamento = true;
        let columns = ['Código', 'Rubrica'];
        let values = ['codigo', 'nome'];
        let modal = this.BuscaAvancadaService.open(this, this, this.Eventos, columns, values, 'Rubricas', null, 'rubrica', true, true, false, null);
        modal.result.then(() => { /* */ }).catch(() => { /* */ });
    }

    addRubrica() {
        if (this.rubrica != null) {
            let entity: any = {};
            entity.rubrica = this.rubrica;
            entity.estabelecimento = this.$rootScope.session.entities.estabelecimento;
            if (this.rubricasapontamento.length === 0) {
                entity.ordem = 1;
            } else if (this.rubricasapontamento.length > 0) {
                entity.ordem = (this.rubricasapontamento.slice(-1).pop().ordem) + 1;
            }
            this.ApontamentosRubricasapontamento.save(entity);
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'É preciso selecionar uma rubrica'
            });
        }
    }

    removerRubrica(rubrica: any) {
        this.ApontamentosRubricasapontamento.delete(rubrica);
    }

    isLoadingRubricas() {
        return this.ApontamentosRubricasapontamento.loadParams.busy && !this.ApontamentosRubricasapontamento.loadParams.finished;
    }

    isLoadingDocsObrigatorios() {
        return this.TiposDocumentosRequeridosService.loadParams.busy || !this.TiposDocumentosRequeridosService.loadParams.finished;
    }

    isSavingRubricas() {
        return this.ApontamentosRubricasapontamento.loadParams.busySave;
    }

    salvarOrdemRubricas() {
        let url = this.nsjRouting.generate('apontamentos_rubricasapontamento_put_bulk', {}, true);
        this.rubricasapontamento = this.rubricasOnSort(null, this.rubricasapontamento);
        this.salvandoOrdenacao = true;
        this.$http.put(url, this.rubricasapontamento)
            .then((response: any) => {
                this.ApontamentosRubricasapontamento.reload();
                this.NewToaster.pop({
                    type: 'success',
                    title: 'Ordem salva com sucesso.'
                });
            })
            .catch((error: any) => {
                this.NewToaster.pop({
                    type: 'error',
                    title: error.data.message
                });
            }).finally(() => {
                this.salvandoOrdenacao = false;
            });
    }

    rubricasOnSort(e: any, rubrica: any) {
        for (let i = 0; i < rubrica.length; i++) {
            rubrica[i].ordem = i + 1;
        }
        return rubrica;
    }

    validaAntecedenciaFerias(diasAntecedenciaFerias: any) {
        if (!diasAntecedenciaFerias) {
            this.exibeBotaoConfigsGerais = false;
        } else if (diasAntecedenciaFerias <= 365) {
            this.exibeBotaoConfigsGerais = true;
            this.alterouAntecedenciaFerias = true;
        }
    }

    exibeAvisoAntecedenciaFerias(diasAntecedenciaFerias: any) {
        if (!diasAntecedenciaFerias) {
            this.NewToaster.pop({
                type: 'warning',
                title: 'Digite uma quantidade válida',
                body: 'A quantidade de dias deve ser de 1 a 365.',
                timeout: 8000
            });
            this.exibeBotaoConfigsGerais = false;
            return;
        }

        if (diasAntecedenciaFerias <= 365) {
            this.exibeBotaoConfigsGerais = true;
        } else {
            this.NewToaster.pop({
                type: 'warning',
                title: 'Dias acima do limite máximo',
                body: 'A quantidade de dias deve ser de no máximo 365.',
                timeout: 8000
            });
            this.exibeBotaoConfigsGerais = false;
        }
    }

    /**
     * Verifica se o valor digitado para a configuração PRAZO_AVISO_FIM_PERIODO_EXPERIENCIA é válido
     * @param qtdDiasAvisoFimPeriodoExperiencia o valor digitado para a configuração
     */
    validaPrazoAvisoFimPeriodoExperiencia(qtdDiasAvisoFimPeriodoExperiencia: any) {
        this.prazoAvisoFimPeriodoExperienciaValido = qtdDiasAvisoFimPeriodoExperiencia !== null &&
            qtdDiasAvisoFimPeriodoExperiencia !== undefined &&
            qtdDiasAvisoFimPeriodoExperiencia >= 0 &&
            qtdDiasAvisoFimPeriodoExperiencia <= 90 ? true : false;
    }

    /**
     * Verifica se o valor digitado para a configuração PRAZO_AVISO_VENCIMENTO_CNH é válido
     * @param qtdDiasAvisoFimPeriodoExperiencia o valor digitado para a configuração
     */
    validaPrazoAvisoVencimentoCNH(qtdDiasAvisoVencimentoCNH: any) {
        this.prazoAvisoVencimentoCNHValido = qtdDiasAvisoVencimentoCNH !== null &&
            qtdDiasAvisoVencimentoCNH !== undefined &&
            qtdDiasAvisoVencimentoCNH > 0 &&
            qtdDiasAvisoVencimentoCNH <= 180 ? true : false;
    }
    /**
     * Verifica se o valor digitado para a configuração PRAZO_AVISO_VENCIMENTO_OC é válido
     * @param qtdDiasAvisoFimPeriodoExperiencia o valor digitado para a configuração
     */
    validaPrazoAvisoVencimentoOC(qtdDiasAvisoVencimentoOC: any) {
        this.prazoAvisoVencimentoOCValido = qtdDiasAvisoVencimentoOC !== null &&
            qtdDiasAvisoVencimentoOC !== undefined &&
            qtdDiasAvisoVencimentoOC > 0 &&
            qtdDiasAvisoVencimentoOC <= 180 ? true : false;
    }

    /**
     * Controla a exibição do botão de salvar na aba Avisos e pendências. Adicionar validações necessárias no método quando houverem mais condições que evitem a exibição do botão
     * @returns Booleano que indica se o botão será exibido ou não
     */
    abaAvisosPendenciasValida () {
        return this.prazoAvisoFimPeriodoExperienciaValido && this.prazoAvisoVencimentoCNHValido && this.prazoAvisoVencimentoOCValido;
    }


    getPortariaEntity() {
        this.portariaBusy = true;
        this.$http({
            method: 'GET',
            url: this.nsjRouting.generate('pontobundle_portaria_index', {}, true),
        }).then((response) => {
            this.portariaBusy = false;
            if (!response.data || Array.isArray(response.data)) {
                return;
            }
            this.portariaEntity = response.data as PortariaEntity;
        });
    }

    ativarPortaria() {
		this.ConfiguracoesPortariaModalService.open({nome: '', cpf: ''}).result
            .then((response) => {
                this.portariaBusy = true;
                this.$http({
                    method: 'POST',
                    url: this.nsjRouting.generate('pontobundle_portaria_insert', {}, true),
                    data: response
                }).then((response) => {
                    this.portariaEntity = response.data as PortariaEntity;
                }).catch((error) => {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error.data.message
                    });
                }).finally(() => {
                    this.portariaBusy = false;
                });
            }).catch(() => {/**/});
    }

}
