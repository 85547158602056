angular
        .module('main')

        .filter
        (
                'tipoArquivo',
                function ()
                {
                    return function (param)
                    {
                        var type;
                        if (param) {
                            var mimetype = param.split("/");
                            type = (mimetype[0] === 'image') ? mimetype[0] : mimetype[1];
                        }

                        switch (type)
                        {
                            case "vnd.oasis.opendocument.text":
                            case "vnd.openxmlformats-officedocument.wordprocessingml.document":
                            case "msword":
                                return 'nsj-ico-word';
                                break;
                            case "vnd.oasis.opendocument.spreadsheet":
                                return 'nsj-ico-excel';
                                break;
                            case "pdf":
                                return 'nsj-ico-pdf';
                                break;
                            case "image":
                                return 'nsj-ico-image';
                                break;
                            default:
                                return "fa fa-file-o";
                        }
                    };
                }
        )
        .filter(
                'situacaoFuncionarioEnum',
                function () {
                    return function (color)
                    {
                        switch (color)
                        {
                            case '00':
                                return 'Todos exceto rescindidos';
                                break;
                            case '01':
                                return 'Atividade Normal';
                                break;
                            case '02':
                                return 'Contrato Rescindido';
                                break;
                            case '03':
                                return 'Férias';
                                break;
                            case '04':
                                return 'Licença Médica';
                                break;
                            case '05':
                                return 'Acidente de Trabalho';
                                break;
                            case '06':
                                return 'Licença Maternidade';
                                break;
                            case '07':
                                return 'Licença Paternidade';
                                break;
                            case '08':
                                return 'Serviço Militar';
                                break;
                            case '09':
                                return 'Mandato Sindical';
                                break;
                            case '10':
                                return 'Licença não remunerada';
                                break;
                            case '11':
                                return 'Qualificação';
                                break;
                            case '12':
                                return 'Cárcere';
                                break;
                            case '13':
                                return 'Suspenso';
                                break;
                            case '14':
                                return 'Mandato Eleitoral';
                                break;
                            case '15':
                                return 'Cessão';
                                break;
                            case '16':
                                return 'Aposentadoria por Invalidez';
                                break;
                            case '17':
                                return 'Outros';
                                break;
                        }
                    };
                })
        .filter(
                'situacaoFuncionarioClassEnum',
                function () {
                    return function (color)
                    {
                        switch (color)
                        {
                            case '01':
                                return 'ativo';
                                break;
                            case '02':
                                return 'desligado';
                                break;
                            case '03':
                                return 'ferias';
                                break;
                            case '04':
                                return 'licenca-medica';
                                break;
                            case '05':
                                return 'acidente-trabalho';
                                break;
                            case '06':
                                return 'licenca-maternidade';
                                break;
                            case '07':
                                return 'licenca-paternidade';
                                break;
                            case '08':
                                return 'servico-militar';
                                break;
                            case '09':
                                return 'mandato-sindical';
                                break;
                            case '10':
                                return 'licenca-sem-vencimentos';
                                break;
                            case '11':
                                return 'qualificacao';
                                break;
                            case '12':
                                return 'carcere';
                                break;
                            case '13':
                                return 'suspenso';
                                break;
                            case '14':
                                return 'mandato-eleitoral';
                                break;
                            case '15':
                                return 'cessao';
                                break;
                            case '16':
                                return 'invalidez';
                                break;
                            case '17':
                                return 'outros';
                                break;
                        }
                    };
                })
        .filter(
                'situacaoSolicitacaoEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao)
                        {
                            case '-1':
                                return 'Rascunho';
                                break;
                            case -1:
                                return 'Rascunho';
                                break;
                            case 0:
                                return 'Enviada';
                                break;
                            case 1:
                                return 'Efetuada';
                                break;
                            case 2:
                                return 'Cancelada';
                                break;
                            case 3:
                                return 'Excluída';
                                break;
                            case 4:
                                return 'Recusada';
                                break;
                        }
                    };
                })
        .filter(
                'situacaoSolicitacaoClassEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao)
                        {
                            case -1:
                                return 'label-rascunho';
                                break;
                            case 0:
                                return 'label-warning';
                                break;
                            case 1:
                                return 'label-success';
                                break;
                            case 2:
                            case 4:
                                return 'label-danger';
                                break;
                            case 3:
                                return 'label-danger';
                                break;

                        }
                    };
                })

        .filter(
                'solicitacaoTipoPromocaoEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao)
                        {
                            case 0:
                                return 'Mudança de cargo';
                                break;
                            case 1:
                                return 'Aumento de nível do cargo';
                                break;
                            case 2:
                                return 'Aumento salarial';
                                break;
                        }
                    };
                })

        .filter
        (
                'weekDayEnum',
                function ()
                {
                    return function (day)
                    {
                        switch (day)
                        {
                            case 0:
                                return 'dom';
                                break;
                            case 1:
                                return 'seg';
                                break;
                            case 2:
                                return 'ter';
                                break;
                            case 3:
                                return 'qua';
                                break;
                            case 4:
                                return 'qui';
                                break;
                            case 5:
                                return 'sex';
                                break;
                            case 6:
                                return 'sab';
                                break;
                        }
                    };
                }
        )

        .filter
        (
                'sexoEnum',
                function ()
                {
                    return function (input)
                    {
                        switch (input)
                        {
                            case 1:
                                return 'Masculino';
                            case 2:
                                return 'Feminino';
                        }
                    };
                }
        )

        .filter
        (
                'estadoCivilEnum',
                function ()
                {
                    return function (input)
                    {
                        switch (input)
                        {
                            case 1:
                                return 'Solteiro';
                            case 2:
                                return 'Casado';
                            case 3:
                                return 'Divorciado';
                            case 4:
                                return 'Viúvo';
                            case 5:
                                return 'União Estável';
                            case 6:
                                return 'Outros';
                        }
                    };
                }
        )

        .filter
        (
                'tipoTrabalhador',
                function ()
                {
                    return function (input)
                    {
                        switch (input)
                        {
                            case 0:
                                return 'Funcionário';
                            case 1:
                                return 'Contribuinte';
                            case 2:
                                return 'Estagiário';
                        }
                    };
                }
        )

        .filter
        (
                'lookupTipoTrabalhador',
                function ()
                {
                    return function (input)
                    {
                        switch (input)
                        {
                            case 0:
                                return 'Tipo de Colaborador: Funcionário(a)';
                            case 1:
                                return 'Tipo de Colaborador: Contribuinte';
                            case 2:
                                return 'Tipo de Colaborador: Estagiário(a)';
                        }
                    };
                }
        )

        .filter
        (
                'racaEnum',
                function ()
                {
                    return function (input)
                    {
                        switch (input)
                        {
                            case 1:
                                return 'Indígena';
                            case 2:
                                return 'Branca';
                            case 4:
                                return 'Negra';
                            case 6:
                                return 'Amarela';
                            case 8:
                                return 'Parda';
                            case 9:
                                return 'Não Informado.';
                        }
                    };
                }
        )

        .filter
        (
                'sangueEnum',
                function ()
                {
                    return function (input)
                    {
                        switch (input)
                        {
                            case - 1:
                                return 'Vazio';
                            case 0:
                                return 'O+';
                            case 1:
                                return 'O-';
                            case 2:
                                return 'A+';
                            case 3:
                                return 'A-';
                            case 4:
                                return 'B+';
                            case 5:
                                return 'B-';
                            case 6:
                                return 'AB+';
                            case 7:
                                return 'AB-';
                        }
                    };
                }
        )

        .filter
        (
                'cnhEnum',
                function ()
                {
                    return function (input)
                    {   
                        switch (input? input.toString(): '')
                        {   
                            case '0':
                                return 'A';
                            case '1':
                                return 'B';
                            case '2':
                                return 'C';
                            case '3':
                                return 'D';
                            case '4':
                                return 'E';
                            case '5':
                                return 'AB';
                            case '6':
                                return 'AC';
                            case '7':
                                return 'AD';
                            case '8':
                                return 'AE';
                            default:
                                return 'Não Informado.';
                        }
                    };
                }
        )

        .filter
        (
                'salarioTipoEnum',
                function ()
                {
                    return function (input)
                    {
                        switch (input)
                        {
                            case 1:
                                return 'Conta Corrente';
                            case 2:
                                return 'Poupança';
                            case 4:
                                return 'Salário';
                            case 8:
                                return 'Dinheiro';
                            case 16:
                                return 'Cheque';
                            case 32:
                                return 'Ordem de Pagamento';
                            case 64:
                                return 'PIX(Agência e Conta)';
                            case 128:
                                return 'PIX(CPF)';
                            case 256:
                                return 'PIX(E-mail)';
                            case 512:
                                return 'PIX(Celular)';
                            case 1024:
                                return 'PIX(Chave Aleatória)';
                        }
                    };
                }
        )

        .filter
        (
                'salarioUnidadeEnum',
                function ()
                {
                    return function (input)
                    {
                        switch (input)
                        {
                            case 1:
                                return 'Hora';
                            case 2:
                                return 'Dia';
                            case 3:
                                return 'Semana';
                            case 4:
                                return 'Mês';
                            case 5:
                                return 'Tarefa';
                            case 6:
                                return 'Variável';
                        }
                    };
                }
        )

        .filter
        (
                'parentescoEnum',
                function ()
                {
                    return function (input)
                    {
                        switch (input)
                        {
                            case 1:
                                return 'Cônjuge';
                            case 2:
                                return 'Companheiro(a) com o(a) qual tenha filho ou viva há mais de 5 (cinco) anos ou possua Declaração de União Estável';
                            case 3:
                                return 'Filho(a) ou enteado(a)';
                            case 4:
                                return 'Filho(a) ou enteado(a) universitário(a) ou cursando escola técnica de 2º grau, até 24 (vinte e quatro) anos';
                            case 6:
                                return 'Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, do(a) qual detenha a guarda judicial';
                            case 9:
                                return 'Pais, avós e bisavós';
                            case 10:
                                return 'Menor pobre do qual detenha a guarda judicial';
                            case 11:
                                return 'A pessoa absolutamente incapaz, da qual seja tutor ou curador';
                            case 12:
                                return 'Ex-cônjuge';
                            case 99:
                                return 'Agregado/Outros';
                        }
                    };
                }
        )
        .filter
        (
                'tipoFeriasEnum',
                function ()
                {
                    return function (input)
                    {
                        switch (input)
                        {
                            case 1:
                                return 'Normal';
                            case 2:
                                return 'Coletiva';
                            case 3:
                                return 'Antecipada';
                        }
                    };
                }
        )
        .filter
        (
                'tipoVtEnum',
                function ()
                {
                    return function (input)
                    {
                        switch (input)
                        {
                            case 1:
                                return 'Ônibus';
                            case 2:
                                return 'Metrô';
                            case 3:
                                return 'Trem';
                            case 4:
                                return 'Barca';
                            case 5:
                                return 'Van';
                        }
                    };
                }
        )
        .filter
        (
                'tipoCalculoEnum',
                function ()
                {
                    return function (input)
                    {
                        switch (input)
                        {
                            case '13c':
                                return 'Complemento 13';
                            case 'Re':
                                return 'Rescisao';
                            case 'Ad':
                                return 'Adiantamento';
                            case '13':
                                return 'Décimo Terceiro';
                            case 'Sin':
                                return 'Sindical';
                            case 'Fo':
                                return 'Folha';
                            case 'Fe':
                                return 'Férias';
                            case 'Foc':
                                return 'Folha Corretiva';
                            case 'Ad13':
                                return 'Adiantamento 13';
                        }
                    };
                }
        )
        .filter(
                'situacaoApuracaopontoprocessamentoEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao)
                        {
                            case null:
                                return 'Aguardando geração';
                                break;
                            case 0:
                                return 'Aguardando geração';
                                break;
                            case 1:
                                return 'Gerando';
                                break;
                            case 2:
                                return 'Geradas';
                                break;
                        }
                    };
                }
            )
        .filter(
                'situacaoApuracaopontoprocessamentopagamentoEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao)
                        {
                            case null:
                                return 'Aguardando geração';
                                break;
                            case 0:
                                return 'Aguardando geração';
                                break;
                            case 1:
                                return 'Gerando';
                                break;
                            case 2:
                                return 'Liberado';
                                break;
                            case 3:
                                return 'Aguardando liberação';
                                break;
                            case 4:
                                return 'Em liberação';
                                break;
                        }
                    };
                }
            )
        .filter(
                'situacaoApuracaopontoprocessamentoClassesEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao)
                        {
                            case null:
                                return 'aguardando-geracao';
                            case 0:
                                return 'aguardando-geracao';
                            case 1:
                            case 3:
                            case 4:
                                return 'gerando';
                            case 2:
                                return 'gerados';
                        }
                    };
                }
            )
            .filter(
                'situacaoApuracaopontoEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao)
                        {
                            case 0:
                                return 'ABERTA';
                                break;
                            case 1:
                                return 'FECHADA';
                                break;
                        }
                    };
                }
            )
            .filter(
                'situacaoApuracaopontoClassesEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao)
                        {
                            case 0:
                                return 'label-success';
                                break;
                            case 1:
                                return 'label-warning';
                                break;
                        }
                    };
                }
            )
            .filter(
                'situacaoFolhasdepontoEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao)
                        {
                            case null:
                            case 0:
                                return 'Aguardando geração';
                            case 1:
                                return 'Gerando';
                            case 2:
                                return 'Gerada';
                            case 3:
                                return 'Liberada';
                            case 4:
                                return 'Falha na geração';
                        }
                    };
                }
            )
            .filter(
                'situacaoFolhasdepontoClassesEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao) {
                            case 0:
                                return 'label-warning';
                            case 1:
                            case 4:
                                return 'label-danger';
                            case 2:
                            case 3:
                                return 'label-success';
                        }
                    };
                }
            )
            .filter(
                'situacaoFolhasdepontoAssinaturaEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao)
                        {
                            case null:
                            case 0:
                                return 'Enviar para assinatura';
                            case 1:
                                return 'Aguardando assinatura';
                            case 2:
                                return 'Assinada';
                            case 3:
                                return 'Assinatura rejeitada';
                        }
                    };
                }
            )
            .filter(
                'situacaoFolhasdepontoAssinaturaClassesEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao)
                        {
                            case null:
                            case 0:
                                return 'label-primary';
                            case 1:
                                return 'label-warning';
                            case 2:
                                return 'label-success';
                            case 3:
                                return 'label-danger';
                        }
                    };
                }
            )
            .filter(
                'situacaoFolhasdepontoAssinaturaSelectEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao)
                        {
                            case null:
                            case 'status_0':
                                return 'Aguardando geração';
                            case 'status_1':
                                return 'Gerando';
                            case 'status_2':
                                return 'Enviar para assinatura';
                            case 'status_4':
                                return 'Falha na geração';
                            case 'situacaoassinatura_1':
                                return 'Aguardando assinatura';
                            case 'situacaoassinatura_2':
                                return 'Assinada';
                            case 'situacaoassinatura_3':
                                return 'Assinatura rejeitada';
                        }
                    };
                }
            )
            .filter(
                'situacaoPortariaEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao) {
                            case 0:
                            case 2:
                                return 'Ativação em andamento';
                            case 1:
                                return 'Ativada';
                            case null:
                            case 3:
                                return 'Não ativada';
                        }
                    };
                }
            )
            .filter(
                'situacaoPortariaClassesEnum',
                function () {
                    return function (situacao)
                    {
                        switch (situacao) {
                            case 0:
                            case 2:
                                return 'label-info';
                            case 1:
                                return 'label-success';
                            case null:
                            case 3:
                                return 'label-warning';
                        }
                    };
                }
            );