declare var nsjGlobals: any;
import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { ISolicitacao } from '../../Solicitacoes/models/solicitacao.model';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { Usuario } from '../../../../core/usuario/models/usuario-model';
import { SituacaoAdmissaoEnum } from '../enums/situacao';
import { StatusProcessoEnum } from '../enums/statusprocesso';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { MeurhAdmissaocompletaService } from '../admissaocompleta.service';
import { MeurhSolicitacoesadmissoesService } from '../../Solicitacoesadmissoes/solicitacoesadmissoes.service';
import { MeurhSolicitacoes } from '../../Solicitacoes/services/solicitacoes.service';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { WorkflowService } from '../../../Workflow/workflow.service';
import { MeurhAcompanhamentoService } from '../../Acompanhamento/acompanhamento.service';
import { IGestoresTrabalhador } from '../../Transferenciacolaboradores/models/transferenciacolaboradores.model';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';

export class MeurhAdmissaocompletaShowController implements ng.IController {
    static $inject = [
        '$scope',
        '$stateParams',
        '$state',
        'MeurhAdmissaocompletaService',
        'MeurhSolicitacoesadmissoes',
        'entity',
        'NewToaster',
        '$rootScope',
        'ModalConfirmService',
        'MeurhSolicitacoes',
        'TiposDocumentosRequeridosService',
        'WorkflowService',
        'MeurhAcompanhamentoService',
        'ConfiguracoesService'
    ];

    public constructors: any = {};
    public action: string = 'show';
    public tipo = this.$stateParams['tipo'];
    public form: angular.IFormController;
    public busy: boolean = false;
    public entitySave: any = {};
    public matriculaAutomatica: boolean = false;
    public path: number = this.$stateParams.path;
    public cardAtencao: boolean = false;
    public toastTimeout: number = 8000;
    public configuracoes: any;
    public dataAtual = new Date();
    public dataLimiteCancelamentoEfetuada?: string;
    public trabalhador?: ITrabalhador;
    public dataEfetuada: string;
    public foiEditado: boolean;
    public dataLimiteRetificacao: string;

    public busyController = {
        foto: false,
        admissaoPreliminar: false,
        dadosGerais: false
    };

    public situacaoAdmissaoEnum = SituacaoAdmissaoEnum;
    public statusProcessoEnum = StatusProcessoEnum;

    // documentos / anexos
    public tiposdocumentosrequeridos: Array<any>;
    public busyTipoDocumentos: boolean = true;
    public editanexo: boolean = false;
    public entityAnexo: IAnexo = {};

    public clienteAna: boolean = nsjGlobals.get('clienteana');
    public configDesativaAdmPreliminar: boolean;

    constructor(
        private $scope: angular.IScope,
        private $stateParams: angular.ui.IStateParamsService,
        private $state: angular.ui.IStateService,
        private entityService: MeurhAdmissaocompletaService,
        private admissoesPreliminarService: MeurhSolicitacoesadmissoesService,
        private entity: any,
        private NewToaster: any,
        private $rootScope: angular.IRootScopeService & { nsjGlobals: any, temPermissao: (arg: string) => boolean, configuracoes: any, usuario: Usuario },
        private ModalConfirmService: any,
        private MeurhSolicitacoes: MeurhSolicitacoes,
        private TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        private WorkflowService: WorkflowService,
        private MeurhAcompanhamentoService: MeurhAcompanhamentoService,
        private Configuracoes: any,
    ) {
        this.definirDataLimiteParaCancelamentoEfetuada();
        this.entityService.reload();
        this.configuracoes = this.Configuracoes.reload();

        this.busy = true;

        this.busyController.foto = true;

        this.entityService.getFoto(this.entity.solicitacao)

        .then(() => {
            this.busyController.foto = false;
        })
        .catch(() => {
            this.busyController.foto = false;
        });

        this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
        this.MeurhAcompanhamentoService.reload();

        // inicio - caso a admissao completa tenha uma admissao preliminar
        this.admissoesPreliminarService.constructors = {
            estabelecimento: this.entity.estabelecimento,
            solicitacaoadmissaocompleta: this.entity.solicitacao,
            situacao: '0',
        };
        this.busyController.admissaoPreliminar = true;
        this.admissoesPreliminarService.reload();

        this.$scope.$on('meurh_solicitacoesadmissoespreliminar', (event: angular.IAngularEvent, args: any): void => {
            args.forEach((arg: { solicitacao: string; situacaoadmissaopreliminar: number; }) => {
                if (arg.solicitacao === this.entity.solicitacao) {
                    this.entity.situacaoadmissaopreliminar = arg.situacaoadmissaopreliminar;
                    return;
                }
            });
        });

        this.$scope.$on('meurh_solicitacoesadmissoes_list_finished', (event: angular.IAngularEvent, args: any): void => {
            this.busyController.admissaoPreliminar = false;
            if (args && args.length > 0) {
                this.entity.admissaopreliminar = args[0];
                this.entity.situacaoadmissaopreliminar = args[0].situacao;
                this.entity.solicitacaoadmissaopreliminar = args[0].solicitacao;
            }
        });
        // fim - caso a admissao completa tenha uma admissao preliminar

        // documentos / anexos
        this.resetTiposDocumentosRequeridosService();
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();

        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                tipodocumentorequerido.file = '';
                return tipodocumentorequerido;
            });
        });

        this.$scope.$watchGroup(['mrh_admsscmplt_frm_shw_cntrllr.busyController.dadosGerais',
            'mrh_admsscmplt_frm_shw_cntrllr.busyController.foto',
            'mrh_admsscmplt_frm_shw_cntrllr.busyController.admissaoPreliminar'], (newValues: any, oldValues: any): void => {
                if (newValues !== oldValues && (newValues.every((busy: boolean) => busy === false))) {
                    this.busy = false;
                }
            });

        $scope.$on('persona_trabalhadores_loaded', (event: any, args: any) => {
            this.trabalhador = args;
        });

        this.$scope.$on('meurh_solicitacoeshistoricos_list_finished', (event: angular.IAngularEvent, args: any): void => {
            if (!!args) {
                args.forEach((historico: any) => {
                    if (historico.tipohistorico === this.situacaoAdmissaoEnum.EFETUADA) {
                        this.dataEfetuada = historico.created_at;
                    }
                    if (historico.tipohistorico === 6) {
                        this.foiEditado = true;
                    }
                });
            }
        });

        this.dataLimiteRetificacao = this.proximoDiaQuinze(this.entity.dataadmissao).format('DD/MM/YYYY');

        this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesadmissoescompleta_');
        this.onSubmitSuccess();
        this.onSubmitError();
        this.onSubmitPreliminarSuccess();
        this.onSubmitPreliminarError();
        this.onRetomarSuccess();
        this.onRetomarError();
        this.onExcluirSuccess();
        this.onExcluirError();

        this.entity.gestaoBeneficioAvancadaAtiva = this.$rootScope.nsjGlobals.a.configuracoes.GESTAO_BENEFICIO_AVANCADA_ATIVA;
        this.configDesativaAdmPreliminar = this.$rootScope.configuracoes.DESABILITAR_ADMISSAO_PRELIMINAR;
    }

    $onInit(): void {
        this.$scope.$on('workflow_reloaded_after', (event: angular.IAngularEvent, args: any): void => {
            this.$state.reload();
        });

        if (this.$rootScope.configuracoes.CODIGO_FUNCIONARIO_AUTOMATICO === true) {
            this.matriculaAutomatica = true;
        }
        this.entityAnexo['solicitacao'] = this.entity.solicitacao;
        this.entityAnexo['casodeuso'] = 'ADMISSOESCOMPLETAS';
        this.alteraEntityInicio();
    }


    // início - navegação
    public goToEdit(camposobrigatorios: number | null = null): void {
        if (this.$rootScope.temPermissao('criar_admissao_comp') &&
            (this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA || (this.entity._editavel && this.entity._acoes.length > 0)) &&
            (this.entity.origem === 1) // origem 1 = meuRH
        ) {
            this.busy = true;
            this.$state.go('meurh_admissaocompleta_edit',
                angular.extend({}, {
                    'solicitacao': this.entity.solicitacao,
                    camposobrigatorios
                })
            );
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'Usuário sem permissão.',
                timeout: this.toastTimeout
            });
        }
    }
    // fim - navegação


    // início - ações do formulário
    public submit(): void {
        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, this.textoModal('admissaocompleta'));

        confirm.result
            .then((): void => {
                const documentosValidados: boolean = this.validaDocumentosAnexos();
                this.form.$submitted = true;

                if (
                    !this.entity.$$__submitting &&
                    this.validaEntity('admissaocompleta') &&
                    documentosValidados
                ) {
                    this.busy = true;
                    this.alteraEntityEnviar();

                    if (
                        this.entity.situacaoadmissaopreliminar !== null &&
                        this.entity.situacaoadmissaopreliminar < 1
                    ) {
                        this.MeurhSolicitacoes.cancelar(this.entity.admissaopreliminar)
                            .then(() => this.entityService.save(this.entitySave))
                            .catch((response: any) => {
                                if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                                    this.NewToaster.pop({
                                        type: 'error',
                                        title: response.data.message,
                                        timeout: this.toastTimeout
                                    });
                                } else {
                                    this.NewToaster.pop({
                                        type: 'error',
                                        title: 'Ocorreu um erro ao cancelar a admissão preliminar.',
                                        timeout: this.toastTimeout
                                    });
                                }
                            });
                    } else {
                        this.entityService.save(this.entitySave);
                    }
                } else {
                    if (this.validaEntity('admissaocompleta') && !documentosValidados) {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Há documento(s) obrigatório(s) não informado(s).',
                            body: 'Favor editar a admissão.',
                            timeout: this.toastTimeout
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Alguns campos do formulário apresentam erros',
                            body: 'Favor editar a admissão.',
                            timeout: this.toastTimeout
                        });
                    }
                }
            })
            .catch(function () {/**/ });
    }

    public submitPreliminar(): void {
        let diasAtrasoAdmissao: number = parseInt(this.configuracoes.ATRASO_ADMISSAO.valor, 10);
        let dataValida: string = moment(moment(this.dataAtual).add(diasAtrasoAdmissao, 'days')).format('YYYY-MM-DD');
        let confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, this.textoModal('admissaopreliminar'));
        confirm.result
            .then((): void => {
                if ((!this.entity.$$__submitting && this.validaEntity('admissaopreliminar') &&
                    !moment(dataValida).isAfter(this.entity.dataadmissao))) {
                    this.busy = true;
                    this.alteraEntityEnviar(true);
                    this.entityService.enviaPreliminar(this.entitySave);
                } else {
                    if (!this.validaEntity('admissaopreliminar')) {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Há campo(s) obrigatório(s) não informado(s)',
                            body: 'Favor editar a admissão.',
                            timeout: this.toastTimeout
                        });
                    } else if (moment(dataValida).isAfter(this.entity.dataadmissao)) {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Data de admissão inválida.',
                            body: 'O envio da admissão preliminar precisa acontecer no mínimo ' + diasAtrasoAdmissao + ' dias antes da data de admissão. Altere a data de admissão ou verifique as configurações do sistema.',
                            timeout: this.toastTimeout
                        });
                    } else {
                        if (this.validaEntity('admissaopreliminar')) {
                            this.NewToaster.pop({
                                type: 'error',
                                title: 'Há campo(s) obrigatório(s) não informado(s)',
                                body: 'Favor editar a admissão.',
                                timeout: this.toastTimeout
                            });
                        } else if (moment(dataValida).isAfter(this.entity.dataadmissao)) {
                            this.NewToaster.pop({
                                type: 'error',
                                title: 'Data de admissão inválida.',
                                body: 'O envio da admissão preliminar precisa acontecer no mínimo ' + diasAtrasoAdmissao + ' dias antes da data de admissão. Altere a data de admissão ou verifique as configurações do sistema.',
                                timeout: this.toastTimeout
                            });
                        } else {
                            this.NewToaster.pop({
                                type: 'error',
                                title: 'Alguns campos do formulário apresentam erros',
                                body: 'Favor editar a admissão.',
                                timeout: this.toastTimeout
                            });
                        }
                    }
                }
            })
            .catch(function () {/**/ });
    }

    public cancelarPreliminar(): void {
        let customText = this.textoModal('cancelarPreliminar');
        let confirm = this.ModalConfirmService.openCancelar(this.entity.admissaopreliminar, 'admissão preliminar', customText);
        confirm.result.
            then((entity: ISolicitacao): void => {
                this.busy = true;
                this.MeurhSolicitacoes.cancelar(this.entity.admissaopreliminar)
                    .then((response: any): void => {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'A admissão preliminar foi cancelada com sucesso.',
                            timeout: this.toastTimeout
                        });
                        this.$state.reload();
                    })
                    .catch((response: any): void => {
                        if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                            this.NewToaster.pop({
                                type: 'error',
                                title: response.data.message,
                                timeout: this.toastTimeout
                            });
                        } else {
                            this.NewToaster.pop({
                                type: 'error',
                                title: 'Ocorreu um erro ao cancelar a admissão preliminar.',
                                timeout: this.toastTimeout
                            });
                        }
                    })
                    .finally((): void => {
                        this.busy = false;
                    });
            })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error,
                        timeout: this.toastTimeout
                    });
                }
            });
    }

    public async encaminhar(opt?: string): Promise<void> {
        const customText = this.textoModal('encaminhar');
        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);

        confirm.result
            .then((): void => {
                this.busy = true;
                const reload: boolean = this.entity.statusprocesso === this.statusProcessoEnum.NAO_ENCAMINHADA;
                this.alteraEntityEnviar(true);

                this.entityService._save(this.entitySave, false)
                    .then(() => {
                        this.entityService.encaminhar(this.entity.solicitacao, opt)
                            .then((response: any) => {
                                this.constructors.solicitacao = this.entity.solicitacao;
                                const titleMessage = opt
                                    ? 'Código gerado com sucesso'
                                    : 'A admissão foi encaminhada com sucesso.';

                                const bodyMessage = opt
                                    ? 'O código da admissão foi copiado, você já pode colar onde quiser para mandar para o futuro colaborador.'
                                    : null;

                                this.NewToaster.pop({
                                    type: 'success',
                                    title: titleMessage,
                                    body: bodyMessage,
                                    timeout: this.toastTimeout
                                });

                                if (opt) {
                                    navigator.clipboard.writeText(response.codigoverificacao);
                                }

                                if (reload) {
                                    this.$state.reload();
                                }
                            })
                            .catch((error) => {
                                const titleMessage = opt
                                    ? 'Não foi possível copiar o código da admissão'
                                    : 'Ocorreu um erro ao encaminhar a admissão';

                                const bodyMessage = error.message
                                    ? error.message
                                    : null;

                                this.NewToaster.pop({
                                    type: 'error',
                                    title: titleMessage,
                                    body: bodyMessage,
                                    timeout: this.toastTimeout
                                });
                            })
                            .finally(() => {
                                this.busy = false;
                                this.$scope.$applyAsync();
                            });
                    })
                    .catch((error) => {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Não foi possível salvar os dados da admissão',
                            body: error.message,
                            timeout: this.toastTimeout
                        });
                    });
            })
            .catch(() => {/**/ });
    }

    public retomar(): void {
        let customText = this.textoModal('retomar');
        let confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);
        confirm.result
            .then((): void => {
                this.busy = true;
                this.entityService.retomar(this.entity.solicitacao);
            })
            .catch(function () {/**/ });
    }

    public excluir(): void {
        let customText = this.textoModal('excluir');
        let confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);
        confirm.result
            .then((): void => {
                this.entityService.delete(this.entity.solicitacao, true);
            })
            .catch(function () {/**/ });
    }

    public cancelar(entity: ISolicitacao): void {
        let customText = {
            title: 'Cancelar admissão',
            subtitle: 'Você está prestes a cancelar a admissão do(a) funcionário(a).',
            confirmText: 'Após o cancelamento, não será possível desfazer. Deseja continuar?',
            confirmTextNum: this.entity.nome,
            closeButton: 'Voltar',
            confirmButton: 'Cancelar admissão',
        };
        let confirm = this.ModalConfirmService.openCancelar(entity, 'admissão completa', customText);
        confirm.result.then((entity: ISolicitacao) => {
            this.busy = true;
            this.MeurhSolicitacoes.cancelar(entity)
                .then((response: any) => {
                    this.NewToaster.pop({
                        type: 'success',
                        title: 'A admissão completa foi cancelada com sucesso.',
                        timeout: this.toastTimeout
                    });
                    this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_cancelada');
                    this.$state.go('meurh_admissaocompleta');
                }).catch((response: any) => {
                    if (typeof (response.message) !== 'undefined' && response.message) {
                        this.NewToaster.pop({
                            type: 'error',
                            title: response.message,
                            timeout: this.toastTimeout
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Ocorreu um erro ao cancelar a admissão completa.',
                            timeout: this.toastTimeout
                        });
                    }
                }).finally(() => {
                    this.busy = false;
                });
        })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error,
                        timeout: this.toastTimeout
                    });
                }
            });
    }

    public async retificarAdmissao(): Promise<void> {
        let customText = this.textoModal('retificacao');
        let confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);

        confirm.result.then((): void => {
            this.busy = true;

            this.entityService.retificarAdmissao(this.entity.solicitacao)
            .then((async () => {
                await this.$state.reload();
            }))
            .catch((response: any) => {
                if (typeof (response.message) !== 'undefined' && response.message) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: response.message,
                        timeout: this.toastTimeout
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao retificar a admissão completa.',
                        timeout: this.toastTimeout
                    });
                }
            }).finally(() => {
                this.busy = false;
            });
        })
        .catch(function () {/**/ });
    }
    // fim - ações do formulário


    // início - controle de botões e campos
    public podeEnviarCompleta(): boolean {
        return this.validaCompleta();
    }

    public podeEnviarPreliminar(): boolean {
        return this.validaPreliminar();
    }

    public podeEncaminhar(): boolean {
        return (
            this.validaEncaminhar() &&
            this.mostrarEncaminhar()
        );
    }

    public podeEnviarEmail(): boolean {
        return (
            this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA &&
            this.entity.statusprocesso !== this.statusProcessoEnum.FINALIZADA &&
            this.entity.statusprocesso !== this.statusProcessoEnum.RETOMADA
        );
    }

    public podeCancelarEfetuada(): boolean {
        const dataAtual = moment();

        return this.entity.wkf_estado === 'Efetuada' && dataAtual.isBefore(moment(this.dataLimiteCancelamentoEfetuada, 'DD/MM/YYYY')) && this.possuiAcaoWorkflow(this.entity._acoes, 'SOLICITAR_CANCELAMENTO');
    }

    public podeRetificar(): boolean {
        const dataProximoDiaQuinze = this.proximoDiaQuinze(this.entity.dataadmissao);
        const dataAtual = moment();

        return (
            this.$rootScope.configuracoes.LIBERAR_RETIFICACAO_ADMISSAO_FUNCIONARIO &&
            this.entity.situacao === this.situacaoAdmissaoEnum.EFETUADA &&
            !this.entity.retificacao &&
            dataAtual.isBefore(dataProximoDiaQuinze)
        );
    }

    public mostrarEnviarPreliminar(): boolean {
        return !this.entity.solicitacaoadmissaopreliminar && this.entity.situacaoadmissaopreliminar !== 1;
    }

    public mostrarEncaminhar(): boolean {
        return (
            this.entity.statusprocesso !== this.statusProcessoEnum.RETOMADA &&
            this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA
        );
    }

    public mostrarCancelarPreliminar(): boolean {
        return (
            this.$rootScope.temPermissao('criar_admissao_comp') &&
            this.entity.solicitacaoadmissaopreliminar &&
            this.entity.situacaoadmissaopreliminar < 1 &&
            this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA
        );
    }

    public mostrarCancelar(): boolean {
        return (
            this.entity._podecancelar !== false &&
            !!this.entity.solicitacao &&
            !this.entity.solicitacaoadmissaopreliminar
        );
    }

    public possuiAcaoWorkflow(arrAcoes: Array<any>, codeAcao: string): boolean {

        if (!arrAcoes || arrAcoes.length < 1) {
            return false;
        } else {
            return arrAcoes.some((acao) => {
                return acao.code === codeAcao;
            });
        }

    }

    // fim - controle de botões e campos

    // início - controle de estilização dinâmica
    public getSituacaoLabel(): string {
        if (this.entity.situacao === SituacaoAdmissaoEnum.ABERTA) {
            return 'label label-aberta';
        }

        if (this.entity.situacao === SituacaoAdmissaoEnum.ENVIADA) {
            return 'label label-warning';
        }

        if (this.entity.situacao === SituacaoAdmissaoEnum.EFETUADA) {
            return 'label label-success';
        }

        if (
            this.entity.situacao === SituacaoAdmissaoEnum.CANCELADA ||
            this.entity.situacao === SituacaoAdmissaoEnum.EXCLUIDA ||
            this.entity.situacao === SituacaoAdmissaoEnum.RECUSADA
        ) {
            return 'label label-danger';
        }

        return '';
    }

    public getSituacaoBindTemplate(): string {

        if (this.entity.wkf_estado) {
            return this.entity.wkf_estado;
        }

        switch (this.entity.situacao) {
            case SituacaoAdmissaoEnum.ABERTA:
                return 'Aberta';

            case SituacaoAdmissaoEnum.ENVIADA:
                return 'Enviada';

            case SituacaoAdmissaoEnum.EFETUADA:
                return 'Efetuada';

            case SituacaoAdmissaoEnum.CANCELADA:
                return 'Cancelada';

            case SituacaoAdmissaoEnum.EXCLUIDA:
                return 'Excluída';

            case SituacaoAdmissaoEnum.RECUSADA:
                return 'Recusada';

            default:
                return ' - ';
        }
    }

    public getSituacaoEncaminhadaBindTemplate(): string {

        if (
            this.entity.situacao !== SituacaoAdmissaoEnum.EXCLUIDA &&
            (this.entity.statusprocesso === StatusProcessoEnum.ENVIADA || this.entity.statusprocesso === StatusProcessoEnum.EM_ACOMPANHAMENTO)
        ) {
            return 'encaminhada';

        } else if (this.entity.situacao === SituacaoAdmissaoEnum.ABERTA && !!this.entity.retificacao) {
            return !this.foiEditado ? 'retificação aberta' : 'retificação em rascunho';

        } else if (this.entity.situacao === SituacaoAdmissaoEnum.ENVIADA && !!this.entity.retificacao) {
            return 'retificação enviada';

        } else if (this.entity.situacao === SituacaoAdmissaoEnum.EFETUADA && !!this.entity.retificacao && !this.entity.retificacao?.solicitacaooriginal) {
            return this.entity.retificacao.situacao !== SituacaoAdmissaoEnum.EFETUADA ? 'em retificação' : 'retificada';

        } else if (this.entity.situacao === SituacaoAdmissaoEnum.EFETUADA && !!this.entity.retificacao?.solicitacaooriginal) {
            return 'retificação efetuada';

        } else {
            return '';
        }

    }

    // fim - controle de estilização dinâmica

    // início - validações
    private validaCompleta(): boolean {
        return (
            this.validaDadosGerais() &&
            this.validaDocumentosAnexos() &&
            this.validaContrato() &&
            this.validaEndereco() &&
            this.validaDadosContato() &&
            this.validaDadosPessoais() &&
            this.validaDocumentacao() &&
            this.validaOutrasInformacoes()
        );
    }

    private validaPreliminar(): boolean {
        return (
            this.validaMatricula() &&
            !!this.entity.nome &&
            !!this.entity.cargo &&
            !!this.entity.nivelcargo &&
            !!this.entity.departamento &&
            !!this.entity.salariofixo &&
            !!this.entity.dataadmissao &&
            !!this.entity.horario &&
            !!this.entity.datanascimento &&
            !!this.entity.cpf
        );
    }

    private validaEncaminhar(): boolean {
        return (
            this.validaMatricula() &&
            !!this.entity.nome &&
            !!this.entity.email &&
            this.validaDadosVaga() &&
            this.validaDadosEmpresa() &&
            this.validaSalarioFixo() &&
            this.validaAdmissao() &&
            this.validaOutrasInformacoesGerais()
        );
    }

    private validaDadosGerais(): boolean {
        return (
            this.validaIdentificacao() &&
            this.validaDadosVaga() &&
            this.validaDadosEmpresa() &&
            this.validaSalarioFixo() &&
            this.validaAdmissao() &&
            this.validaOutrasInformacoesGerais()
        );
    }

    private validaIdentificacao(): boolean {
        const emailValid = this.validaEmail();

        return (
            this.validaMatricula() &&
            !!this.entity.nome &&
            !!this.entity.dataadmissao &&
            !!this.entity.cpf &&
            this.form.cpf.$valid &&
            !!this.entity.datanascimento &&
            emailValid
        );
    }

    private validaMatricula(): boolean {
        return this.matriculaAutomatica || !!this.entity.matricula;
    }

    private validaDadosVaga(): boolean {
        return (
            !!this.entity.vagaAtiva === !!this.entity.vaga &&
            !!this.entity.cargo &&
            !!this.entity.nivelcargo
        );
    }

    private validaDadosEmpresa(): boolean {
        return (
            !!this.entity.estabelecimento &&
            !!this.entity.departamento &&
            !!this.entity.lotacao
        );
    }

    private validaSalarioFixo(): boolean {
        return (
            !!this.entity.salariofixo &&
            !!this.entity.unidadesalariofixo
        );
    }

    private validaSalarioFixoComPisoSindicato(): boolean {

        if (this.entity.salariofixo && this.entity.sindicato?.pisosalarial && this.entity.sindicato.pisosalarial !== '0' && this.entity.unidadesalariofixo === '4') {
            return parseFloat(this.entity.salariofixo) >= parseFloat(this.entity.sindicato.pisosalarial);
        } else {
            return true;
        }

    }

    private validaAdmissao(): boolean {
        return (
            !!this.entity.tipoadmissao &&
            !!this.entity.motivoadmissao
        );
    }

    private validaOutrasInformacoesGerais(): boolean {
        return (
            !!this.entity.sindicato &&
            !!this.entity.categoriatrabalhador &&
            !!this.entity.horario &&
            !!this.entity.regimejornada
        );
    }

    private validaDocumentosAnexos(): boolean {
        return this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio || tipodocumentorequerido.fileSelecionado;
        });
    }

    private validaContrato(): boolean {
        return (
            this.validaPrazo() &&
            this.validaPeriodos() &&
            this.validaOutrasInformacoesContrato() &&
            this.validaContratacaoTemporaria()
        );
    }

    private validaPrazo(): boolean {
        return (
            this.validaDiasFimContrato() &&
            this.validaDiasexperienciacontrato()
        );
    }

    private validaDiasFimContrato(): boolean {
        return this.entity.tipocontrato !== '2' || !!this.entity.datafimcontrato;
    }

    private validaDiasexperienciacontrato(): boolean {
        return this.entity.tipocontrato === '2'
            ? (
                this.entity.categoriatrabalhador.categoriatrabalhador === '105' ||
                this.entity.categoriatrabalhador.categoriatrabalhador === '106' || // trabalhador temporário
                !!this.entity.diasexperienciacontrato
            )
            : true;
    }

    private validaPeriodos(): boolean {
        return !(
            !(!!this.entity.numerodiasperiodo) ||
            this.entity.numerodiasperiodo > 31 ||
            !(!!this.entity.numerohorasmensais) ||
            !(!!this.entity.quantidademediahorassemanais)
        );
    }

    private validaOutrasInformacoesContrato(): boolean {
        return (
            !!this.entity.tiporegimetrabalhista &&
            !!this.entity.tipoatividade
        );
    }

    private validaContratacaoTemporaria(): boolean {
        return (
            this.entity.categoriatrabalhador.categoriatrabalhador !== '106' ||
            (
                !!this.entity.motivocontratacaotemporaria &&
                !!this.entity.tipoinclusaotemporaria
            ) &&
            (
                this.entity.motivocontratacaotemporaria !== 1 ||
                this.form.cpftrabalhadorsubstituido.$valid
            )
        );
    }

    private validaEndereco(): boolean {

        let infosValidasPaisEstrangeiro: boolean = false;

        if (this.entity.pais) {
            if (this.entity.pais.pais !== '1058') {
                infosValidasPaisEstrangeiro = !!this.entity.formatributacaoexterior;
            } else if (this.entity.pais.pais === '1058') {
                infosValidasPaisEstrangeiro = true;
            }
        }

        return (
            !!this.entity.tipologradouro &&
            !!this.entity.logradouro &&
            !!this.entity.numero &&
            !!this.entity.cep &&
            !!this.entity.municipio &&
            !!this.entity.pais &&
            infosValidasPaisEstrangeiro
        );
    }

    private validaDadosContato(): boolean {
        const telofonesValid = this.validaTelefones();

        return telofonesValid;
    }

    private validaTelefones(): boolean {
        return (
            this.validaTelefone() &&
            this.validaCelular()
        );
    }

    private validaTelefone(): boolean {
        return !(!!this.entity.telefone) || this.entity.telefone.length >= 8;
    }

    private validaCelular(): boolean {
        return !(!!this.entity.celular) || this.entity.celular.length >= 8;
    }

    private validaEmail(): boolean {
        return (
            !!this.entity.emailcorporativo ||
            !!this.entity.email
        );
    }

    private validaDadosPessoais(): boolean {
        return (
            this.validaNascimento() &&
            this.validaIdentificacaoDadosPessoais()
        );
    }

    private validaNascimento(): boolean {

        let infosValidasPaisEstrangeiro: boolean = false;

        if (this.entity.paisnascimento) {
            if (this.entity.paisnascimento.pais !== '1058') {
                infosValidasPaisEstrangeiro = !!this.entity.datachegadapais && !!this.entity.temporesidenciaestrangeiro && !!this.entity.classificacaoestrangeiro;
            } else if (this.entity.paisnascimento.pais === '1058') {
                infosValidasPaisEstrangeiro = true;
            }
        }

        return (
            !!this.entity.municipionascimento &&
            !!this.entity.paisnascimento &&
            !!this.entity.paisnacionalidade &&
            infosValidasPaisEstrangeiro
        );
    }

    private validaIdentificacaoDadosPessoais(): boolean {

        let validacoesClienteAna = true;

        if (this.clienteAna && !this.entity.semfiliacao) {
            validacoesClienteAna = !!this.entity.nomemae || !!this.entity.nomepai;
        }

        return (
            !!this.entity.sexo &&
            !!this.entity.estadocivil &&
            !!this.entity.raca &&
            !!this.entity.grauinstrucao &&
            validacoesClienteAna
        );
    }

    private validaDocumentacao(): boolean {
        return (
            this.validaDocumentacaoObrigatoria() &&
            this.validaCtps()
        );
    }

    private validaDocumentacaoObrigatoria(): boolean {
        return (
            this.validaNis()
        );
    }

    private validaNis(): boolean {
        if (!this.entity.primeiroemprego) {
            var ftap: string = '3298765432';
            var total: number = 0;
            var resto: number = 0;
            var strResto: string = '';
            var numPIS: string = this.entity.nis;

            if (!numPIS) {
                this.form.nis.$error = { 'nisInvalido': true };
                return false;
            }

            for (var i = 0; i <= 9; i++) {
                var resultado: number = parseInt(numPIS.slice(i, i + 1), 10) * parseInt(ftap.slice(i, i + 1), 10);
                total = total + resultado;
            }

            resto = (total % 11);

            if (resto !== 0) {
                resto = 11 - resto;
            }

            if (resto === 10 || resto === 11) {
                strResto = resto + '';
                resto = parseInt(strResto.slice(1, 2), 10);
            }

            if (resto !== parseInt(numPIS.slice(10, 11), 10)) {
                this.form.nis.$error = { 'nisInvalido': true };
                return false;
            } else {
                this.form.nis.$error = { 'nisInvalido': false };
                return true;
            }
        } else {
            this.form.nis.$error = {};
            return true;
        }
    }

    private validaCtps(): boolean {
        return !(!!this.entity.numeroctps && !(!!this.entity.ufctps && !!this.entity.seriectps));
    }

    private validaOutrasInformacoes(): boolean {
        return (
            this.validaInformacoesGerais() &&
            this.validaFerias() &&
            this.validaRecebimento()
        );
    }

    private validaPercentualAdiantamento(percentual: number): boolean {
        const PERCENTUAL_MIN = 0; // 0%
        const PERCENTUAL_MAX = 0.4; // 40%

        return percentual >= PERCENTUAL_MIN && percentual <= PERCENTUAL_MAX;
    }

    private validaInformacoesGerais(): boolean {
        return (
            !!this.entity.vinculo &&
            !!this.entity.datavencimentoatestadomedico &&
            this.validaPercentualAdiantamento(this.entity.percentualadiantamento)
        );
    }

    private validaFerias(): boolean {
        return (
            !!this.entity.inicioperiodoaquisitivoferias
        );
    }

    private validaRecebimento(): boolean {
        return (
            this.entity.tiporecebimentosalario &&
            this.validaDadosBancarios() &&
            this.validaDadosPix()
        );
    }

    private validaDadosBancarios(): boolean {
        if (
            !this.entity.semdadosbancarios &&
            (
                this.entity.tiporecebimentosalario === '1' ||
                this.entity.tiporecebimentosalario === '2' ||
                this.entity.tiporecebimentosalario === '4' ||
                this.entity.tiporecebimentosalario === '64'
            )
        ) {

            let tipoContaPixValida: boolean = true;

            if (this.entity.tiporecebimentosalario === '64' && !(!!this.entity.tipocontapix)) {
                tipoContaPixValida = false;
            }

            return (
                !!this.entity.banco &&
                (!!this.entity.agencia || !!this.entity.agencianumero) &&
                !!this.entity.numerocontasalario &&
                !!this.entity.numerocontasalariodv &&
                tipoContaPixValida
            );
        }
        return true;
    }

    private validaDadosPix(): boolean {
        if (
            this.entity.tiporecebimentosalario === '128' ||
            this.entity.tiporecebimentosalario === '256' ||
            this.entity.tiporecebimentosalario === '512' ||
            this.entity.tiporecebimentosalario === '1024'
        ) {
            return !!this.entity.chavepix;
        }
        return true;
    }

    private validaEntity(tipo: string): boolean {
        if (tipo === 'admissaocompleta' && this.validaCompleta()) {
            this.entity.matricula = this.entity.matricula.toUpperCase();
            this.entity.subtiposolicitacao = 0;
            return true;
        } else if (tipo === 'admissaopreliminar' && this.validaPreliminar()) {
            this.entity.matricula = this.entity.matricula.toUpperCase();
            this.entity.subtiposolicitacao = 1;
            return true;
        }

        return false;
    }
    // fim - validações


    // início - observers
    private onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_submitted', (event: angular.IAngularEvent, args: any): void => {
            try {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão foi enviada com sucesso.',
                    timeout: this.toastTimeout
                });
                this.constructors.solicitacao = args.entity.solicitacao;
                this.entity.situacao = this.situacaoAdmissaoEnum.ENVIADA;
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.$state.go('meurh_admissaocompleta_show', this.constructors);
                this.busy = false;
            }
        });
    }

    private onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_submit_error', (event: angular.IAngularEvent, args: any): void => {
            let mensagemErro = '';

            try {
                if (args.response?.data?.errors?.children) {

                    // caso seja um array
                    if (Array.isArray(args.response.data.errors.children)) {
                        args.response.data.errors.children.forEach((element: any) => {
                            mensagemErro = mensagemErro + '&bull; ' + element + '<br>';
                        });
                    } else {
                        // caso seja um objeto
                        for (const campo in args.response.data.errors.children) {
                            if (args.response.data.errors.children[campo]['errors']) {
                                args.response.data.errors.children[campo]['errors'].forEach((element: string) => {
                                    mensagemErro = mensagemErro + '&bull; ' + element + '<br>';
                                });
                            }
                        }
                    }

                } else if (args.response?.data?.message) {
                    mensagemErro = args.response.data.message;
                }

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao enviar a admissão',
                    body: mensagemErro,
                    bodyOutputType: 'trustedHtml',
                    timeout: this.toastTimeout
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao enviar a admissão',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.busy = false;
            }
        });
    }

    private onSubmitPreliminarSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_preliminar_submitted', (event: angular.IAngularEvent, args: any): void => {
            try {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão preliminar foi enviada com sucesso.',
                    timeout: this.toastTimeout
                });

            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.$state.reload();
                this.busy = false;
            }
        });
    }

    private onSubmitPreliminarError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_preliminar_submit_error', (event: angular.IAngularEvent, args: any): void => {
            try {
                let mensagemErro = '';

                if (!!args.response && !!args.response.data) {
                    if (!!args.response.data.errors && !!args.response.data.errors.children) {
                        args.response.data.errors.children.forEach((element: any) => {
                            mensagemErro = mensagemErro + '&bull; ' + element + '<br>';
                        });
                    } else if (!!args.response.data.message) {
                        mensagemErro = args.response.data.message;
                    }
                }

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao enviar a admissão preliminar',
                    body: mensagemErro,
                    bodyOutputType: 'trustedHtml',
                    timeout: this.toastTimeout
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.busy = false;
            }
        });
    }

    private onRetomarSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_retomar_success', (event: angular.IAngularEvent, args: any): void => {
            try {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão foi retomada com sucesso.',
                    timeout: this.toastTimeout
                });

            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.$state.reload();
                this.busy = false;
            }
        });
    }

    private onRetomarError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_retomar_error', (event: angular.IAngularEvent, args: any): void => {
            try {
                let bodyMessage: string | null = null;

                if (!!args.message) {
                    bodyMessage = args.message;
                }

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao retomar a admissão',
                    body: bodyMessage,
                    timeout: this.toastTimeout
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.busy = false;
            }
        });
    }

    private onExcluirSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_deleted', (event: angular.IAngularEvent, args: any): void => {
            try {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão foi excluída com sucesso.',
                    timeout: this.toastTimeout
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.$state.go('meurh_admissaocompleta', {});
                this.busy = false;
            }
        });
    }

    private onExcluirError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_delete_error', (event: angular.IAngularEvent, args: any): void => {
            try {
                if (!!args.response.data && !!args.response.data.message) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao excluir a admissão',
                        body: args.response.data.message,
                        timeout: this.toastTimeout
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao excluir a admissão',
                        timeout: this.toastTimeout
                    });
                }
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.busy = false;
            }
        });
    }
    // fim - observers


    // início - tratamento de dados
    private alteraEntityInicio(): void {
        // dados gerais
        this.entity.unidadesalariofixo = this.entity.unidadesalariofixo ? this.entity.unidadesalariofixo.toString() : this.entity.unidadesalariofixo;
        this.entity.tipoadmissao = this.entity.tipoadmissao ? this.entity.tipoadmissao.toString() : this.entity.tipoadmissao;
        this.entity.regimejornada = this.entity.regimejornada ? this.entity.regimejornada.toString() : this.entity.regimejornada;
        // contrato
        this.entity.tipocontrato = this.entity.tipocontrato.toString();
        this.entity.motivoadmissao = this.entity.motivoadmissao ? this.entity.motivoadmissao.toString() : this.entity.motivoadmissao;
        this.entity.tipoatividade = this.entity.tipoatividade ? this.entity.tipoatividade.toString() : this.entity.tipoatividade;
        this.entity.tiporegimetrabalhista = this.entity.tiporegimetrabalhista ? this.entity.tiporegimetrabalhista.toString() : this.entity.tiporegimetrabalhista;
        this.entity.tipoinclusaotemporaria = this.entity.tipoinclusaotemporaria ? this.entity.tipoinclusaotemporaria.toString() : this.entity.tipoinclusaotemporaria;
        // dados pessoais
        this.entity.sexo = this.entity.sexo ? this.entity.sexo.toString() : this.entity.sexo;
        this.entity.estadocivil = this.entity.estadocivil ? this.entity.estadocivil.toString() : this.entity.estadocivil;
        this.entity.tiposanguineo = this.entity.tiposanguineo !== null ? this.entity.tiposanguineo.toString() : this.entity.tiposanguineo;
        this.entity.grauinstrucao = this.entity.grauinstrucao ? this.entity.grauinstrucao.grauinstrucao : null;
        // documentacao
        this.entity.tipocertidao = this.entity.tipocertidao ? this.entity.tipocertidao.toString() : this.entity.tipocertidao;
        this.entity.categoriacnh = this.entity.categoriacnh ? this.entity.categoriacnh.toString() : this.entity.categoriacnh;
        // outras informacoes
        this.entity.agencianumero = this.entity.agencia ? this.entity.agencia.agencianumero : this.entity.agencianumero;
        this.entity.tiporecebimentosalario = this.entity.tiporecebimentosalario !== undefined && this.entity.tiporecebimentosalario !== null
            ? this.entity.tiporecebimentosalario.toString()
            : this.entity.tiporecebimentosalario;

        this.entity.tipocontapix = this.entity.tiporecebimentosalario === '64' && this.entity.tipocontapix ? this.entity.tipocontapix.toString() : null;

        this.entity.percentualadiantamento /= 100;

        if (!!this.entity.dependentes && this.entity.dependentes.length > 0) {
            this.entity.dependentes.forEach((element: any) => {
                element.percentualpensaoalimenticia /= 100;
                element.percentualpensaoalimenticiafgts /= 100;
            });
        }

        this.entity.temporesidenciaestrangeiro = !!this.entity.temporesidenciaestrangeiro ? this.entity.temporesidenciaestrangeiro.toString() : this.entity.temporesidenciaestrangeiro;
        this.entity.classificacaoestrangeiro = !!this.entity.classificacaoestrangeiro ? this.entity.classificacaoestrangeiro.toString() : this.entity.classificacaoestrangeiro;
    }

    private alteraEntityEnviar(rascunho: boolean = false): void {
        this.entitySave = angular.copy(this.entity);
        this.entitySave.situacao = rascunho ? this.situacaoAdmissaoEnum.ABERTA : this.situacaoAdmissaoEnum.ENVIADA;

        // dados gerais
        this.entitySave.vaga = this.entitySave.vaga ? this.entitySave.vaga.vaga : null;
        this.entitySave.estabelecimento = this.entitySave.estabelecimento ? this.entitySave.estabelecimento.estabelecimento : null;
        this.entitySave.cargo = this.entitySave.cargo ? this.entitySave.cargo.cargo : null;
        this.entitySave.nivelcargo = this.entitySave.nivelcargo ? this.entitySave.nivelcargo.nivelcargo : null;
        this.entitySave.departamento = this.entitySave.departamento ? this.entitySave.departamento.departamento : null;
        this.entitySave.lotacao = this.entitySave.lotacao ? this.entitySave.lotacao.lotacao : null;
        this.entitySave.grupodeponto = this.entitySave.grupodeponto ? this.entitySave.grupodeponto.grupo : null;
        this.entitySave.sindicato = this.entitySave.sindicato ? this.entitySave.sindicato.sindicato : null;
        this.entitySave.horario = this.entitySave.horario ? this.entitySave.horario.horario : null;
        this.entitySave.categoriatrabalhador = this.entitySave.categoriatrabalhador ? this.entitySave.categoriatrabalhador.categoriatrabalhador : null;

        this.entitySave.gestores = [];
        if (this.entity.gestores) {
            this.entity.gestores.map((gestor: IGestoresTrabalhador) => {
                if (gestor.gestor) {
                    this.entitySave.gestores?.push({ 'gestor': gestor.gestor });
                } else {
                    this.entitySave.gestores?.push({ 'identificacaonasajongestor': gestor.email });
                }
            });
        }
        this.entitySave.gestores = JSON.stringify(this.entitySave.gestores);

        // endereço
        this.entitySave.tipologradouro = this.entitySave.tipologradouro ? this.entitySave.tipologradouro.tipologradouro : null;
        this.entitySave.municipio = this.entitySave.municipio ? this.entitySave.municipio.ibge : null;
        this.entitySave.pais = this.entitySave.pais ? this.entitySave.pais.pais : null;
        // dados pessoais
        this.entitySave.municipionascimento = this.entitySave.municipionascimento ? this.entitySave.municipionascimento.ibge : null;
        this.entitySave.paisnascimento = this.entitySave.paisnascimento ? this.entitySave.paisnascimento.pais : null;
        this.entitySave.paisnacionalidade = this.entitySave.paisnacionalidade ? this.entitySave.paisnacionalidade.pais : null;
        this.entitySave.raca = this.entitySave.raca ? this.entitySave.raca.raca : null;
        // outras informações
        this.entitySave.vinculo = this.entitySave.vinculo ? this.entitySave.vinculo.vinculo : null;
        this.entitySave.percentualadiantamento *= 100;
        this.entitySave.agencia = null;
        if (this.entitySave.tiporecebimentosalario === undefined || this.entitySave.tiporecebimentosalario === null) {
            delete this.entitySave.tiporecebimentosalario;
            delete this.entitySave.tipocontapix;
        } else if (this.entitySave.tiporecebimentosalario === '64') {
            this.entitySave.tipocontapix = parseInt(this.entitySave.tipocontapix, 10);
        }

        if (this.entitySave.banco) {
            this.entitySave.banco = this.entitySave.banco.banco ? this.entitySave.banco.banco : null;
        }

        if (this.entitySave.beneficios) {
            this.entitySave.beneficios.forEach((element: any) => {
                element.lotacao = angular.copy(element.lotacao.lotacao);
                element.instituicao = element.instituicao ? angular.copy(element.instituicao.instituicao) : element.instituicao;
                if (element.usobeneficio === 2) {
                    element.cpfdependente = element.dependenteSelecionado.cpf;
                }

                if (element.usobeneficio === 1 && element.cpfdependente != null) {
                    element.cpfdependente = element.dependente = null;
                }

            });

            this.entitySave.beneficios = JSON.stringify(this.entitySave.beneficios);
        }

        if (this.entitySave.dependentes) {
            this.entitySave.dependentes.forEach((element: any) => {
                element.datainclusao = element.datainclusao ? element.datainclusao : null;
                element.databaixaimpostorenda = element.databaixaimpostorenda ? element.databaixaimpostorenda : null;
                element.vencimentoescolar = element.vencimentoescolar ? element.vencimentoescolar : null;
                element.vencimentovacinacao = element.vencimentovacinacao ? element.vencimentovacinacao : null;
                element.agencia = element.agencia ? angular.copy(element.agencia.agencia) : element.agencia;
                element.percentualpensaoalimenticia *= 100;
                element.percentualpensaoalimenticiafgts *= 100;
            });

            this.entitySave.dependentes = JSON.stringify(this.entitySave.dependentes);
        }

        if (this.entitySave.vts) {
            this.entitySave.vts.forEach((element: any) => {
                element.tarifaconcessionariavt = angular.copy(element.tarifaconcessionariavt.tarifaconcessionariavt);
            });

            this.entitySave.vts = JSON.stringify(this.entitySave.vts);
        }

        this.entitySave.temporesidenciaestrangeiro = !!this.entitySave.temporesidenciaestrangeiro ? parseInt(this.entitySave.temporesidenciaestrangeiro, 10) : null;
        this.entitySave.classificacaoestrangeiro = !!this.entitySave.classificacaoestrangeiro ? this.entitySave.classificacaoestrangeiro : null;

        this.entitySave.ambiente = this.entitySave.ambiente ? this.entitySave.ambiente.ambiente : null;
        this.entitySave.condicaoambientetrabalho = this.entitySave.condicaoambientetrabalho ? this.entitySave.condicaoambientetrabalho.condicaoambientetrabalho : null;
    }

    private textoModal(tipo: string): object {
        let customText = {
            'admissaopreliminar': {
                title: 'Enviar admissão preliminar',
                alertInfo: {
                    header: 'Sobre a admissão preliminar',
                    text: 'Após você enviar a admissão preliminar, não será mais possível editar os dados informados.' +
                    (!this.validaSalarioFixoComPisoSindicato() ? ` O salário fixo tem um valor inferior ao piso do sindicato, que é de R$ ${parseFloat(this.entity.sindicato.pisosalarial).toFixed(2)}` : '')
                },
                confirmText: 'Você tem certeza que deseja enviar a admissão preliminar de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, enviar'
            },
            'admissaocompleta': {
                title: 'Enviar admissão completa',
                alertInfo: {
                    header: 'Sobre a admissão',
                    text: 'Após você enviar a admissão completa, não será mais possível editá-la.' +
                    (!this.validaSalarioFixoComPisoSindicato() ? ` O salário fixo tem um valor inferior ao piso do sindicato, que é de R$ ${parseFloat(this.entity.sindicato.pisosalarial).toFixed(2)}` : '')
                },
                confirmText: 'Você tem certeza que deseja enviar a admissão completa de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, enviar'
            },
            'retomar': {
                title: 'Retomar admissão',
                alertInfo: {
                    header: 'Sobre a retomada da admissão',
                    text: 'Após você retomar a admissão, não será mais possível encaminhá-la novamente.'
                },
                confirmText: 'Caso você retome a admissão, ' + this.entity.nome + ' não poderá mais preencher seus próprios dados pelo aplicativo. Você tem certeza que deseja retomar a admissão?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, retomar',
            },
            'excluir': {
                title: 'Excluir admissão',
                subtitle: 'Todas as informações preenchidas serão perdidas.',
                confirmText: 'Você tem certeza que deseja excluir a admissão de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, excluir',
            },
            'encaminhar': {
                title: 'Encaminhar admissão',
                alertInfo: {
                    header: 'Sobre a admissão',
                    text: 'Após você encaminhar a admissão, os campos que o futuro colaborador pode preencher através do aplicativo ficarão bloqueados para edição até o momento que o mesmo termine de preencher os dados, ou até que seja necessário retomar a admissão.'
                },
                confirmText: 'Você tem certeza que deseja encaminhar a admissão para ' + this.entity.nome + ' informar os dados pelo aplicativo?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, encaminhar'
            },
            'cancelarPreliminar': {
                title: 'Cancelar admissão',
                subtitle: 'Você está prestes a cancelar a admissão do(a) funcionário(a).',
                confirmText: 'Após o cancelamento, não será possível desfazer. Deseja continuar?',
                confirmTextNum: this.entity.nome,
                closeButton: 'Voltar',
                confirmButton: 'Cancelar admissão',
            },
            'retificacao': {
                title: 'Enviar retificação',
                subtitle: 'Você está prestes a retificar a admissão do(a) funcionário(a).',
                confirmText: `Você tem certeza que deseja retificar a admissão de ${this.entity.nome}?`,
                closeButton: 'Voltar',
                confirmButton: 'Enviar retificação',
            }
        };

        return customText[tipo];
    }

    private resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': TipoSolicitacaoEnum.ADMISSOESCOMPLETAS };
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }

    private definirDataLimiteParaCancelamentoEfetuada(): void {
        if (this.entity.situacao !== this.situacaoAdmissaoEnum.EFETUADA) {
            return;
        }

        const dataAdmissao = this.proximoDiaQuinze(this.entity.dataadmissao);

        this.dataLimiteCancelamentoEfetuada = dataAdmissao.format('DD/MM/YYYY');
    }

    private proximoDiaQuinze(data: string): moment.Moment {
        const dataProximoDiaQuinze = moment(data);

        dataProximoDiaQuinze.add(1, 'month');
        dataProximoDiaQuinze.date(15);

        return dataProximoDiaQuinze;
    }
    // fim - tratamento de dados
}
