import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { MotivocontratacaotemporariaEnum } from '../../enums/motivocontratacaotemporaria.enum';
import { TipoinclusaotemporariaEnum } from '../../enums/tipoinclusaotemporaria.enum';

export class MeurhAdmissoesContratoFormController implements ng.IController {

    static $inject = [];

    public entity: any;
    public desativaDatafimcontrato: boolean = false;
    public form: angular.IFormController;
    public desativaportrabalhadortemporario: boolean;
    public action: string;
    public first_load: boolean = true;
    public tipoInclusaoOptions: Array<{ value: string, label: string }> = [];

    public anoMinimo: moment.Moment;
    public anoMaximo: moment.Moment;

    public motivocontratacaotemporariaEnum = MotivocontratacaotemporariaEnum;
    public tipoinclusaotemporariaEnum = TipoinclusaotemporariaEnum;

    constructor() {
        this.getTipoInclusaoOptions();
    }

    public $onInit(): void {
        this.verificaDatafimcontrato();
        this.bloqueiaAnoMinimo();
        this.bloqueiaAnoMaximo();
    }

    public verificaDatafimcontrato(): void {
        if (this.entity.tipocontrato === '2') {
            this.desativaDatafimcontrato = false;
        } else {
            this.desativaDatafimcontrato = true;
            this.entity.datafimcontrato = '';
            this.entity.dataprorrogacaocontrato = '';
            if (this.form !== undefined && this.form.datafimcontrato !== undefined) {
                this.form.datafimcontrato.$error.required = false;
            }
        }
    }

    public getDiasexperienciacontrato(event: angular.IAngularEvent & { detail: any }): void {
        if (!event.detail.value && event.detail.value !== 0) {
            event.detail.error = true;
        }

        this.entity.diasexperienciacontrato = event.detail.value;
        this.entity.erroDiasexperienciacontrato = event.detail.error;
        this.calculaDatafimexperiencia();
        this.calculaDatafimprorrogacao();
    }

    public getProrrogacaoexperiencia(event: angular.IAngularEvent & { detail: any }): void {
        if (!event.detail.value && event.detail.value !== 0) {
            event.detail.error = true;
        }

        this.entity.prorrogacaoexperiencia = event.detail.value;
        this.entity.erroProrrogacaoexperiencia = event.detail.error;
        this.calculaDatafimprorrogacao();
        this.first_load = false;
    }

    public getNumerodiasperiodo(event: angular.IAngularEvent & { detail: any }): void {
        if (!event.detail.value && event.detail.value !== 0) {
            event.detail.error = true;
        }

        this.entity.numerodiasperiodo = event.detail.value;
        this.entity.erroNumerodiasperiodo = event.detail.error;
    }

    public getNumerohorasmensais(event: angular.IAngularEvent & { detail: any }): void {
        if (!event.detail.value && event.detail.value !== 0) {
            event.detail.error = true;
        }

        this.entity.numerohorasmensais = event.detail.value;
        this.entity.erroNumerohorasmensais = event.detail.error;
    }

    public getQuantidademediahorassemanais(event: angular.IAngularEvent & { detail: any }): void {
        if (!event.detail.value && event.detail.value !== 0) {
            event.detail.error = true;
        }

        this.entity.quantidademediahorassemanais = event.detail.value;
        this.entity.erroQuantidademediahorassemanais = event.detail.error;
    }

    public calculaDatafimexperiencia(): void {
        if (
            !!this.entity.dataadmissao &&
            this.entity.diasexperienciacontrato !== 0 &&
            this.entity.tipocontrato === '2'
        ) {
            if (!this.first_load) {
                this.entity.datafimcontrato = moment(this.entity.dataadmissao).add(this.entity.diasexperienciacontrato - 1, 'days').format('YYYY-MM-DD');
            }
        } else if (
            !!this.entity.datafimcontrato &&
            this.entity.diasexperienciacontrato === 0
        ) {
            if (!this.first_load) {
                this.entity.datafimcontrato = null;
            }
        }
    }

    public calculaDatafimprorrogacao(): void {
        if (
            this.entity.datafimcontrato &&
            this.entity.prorrogacaoexperiencia !== 0 &&
            this.entity.tipocontrato === '2'
        ) {
            if (!this.first_load) {
                this.entity.dataprorrogacaocontrato = moment(this.entity.datafimcontrato).add(this.entity.prorrogacaoexperiencia, 'days').format('YYYY-MM-DD');
            }
        } else if (
            !this.entity.datafimcontrato ||
            (this.entity.dataprorrogacaocontrato && this.entity.prorrogacaoexperiencia === 0)
        ) {
            if (!this.first_load) {
                this.entity.dataprorrogacaocontrato = null;
            }
        }
    }

    public motivocontratacaotemporariaChange(): void {
        delete this.entity.cpftrabalhadorsubstituido;
    }

    public exigeDiasExperienciaInicial() {
        return !this.entity.categoriatrabalhador || (this.entity.categoriatrabalhador.categoriatrabalhador !== '105' && this.entity.categoriatrabalhador.categoriatrabalhador !== '106');
    }

    private getTipoInclusaoOptions(): void {
        this.tipoInclusaoOptions = [
            {
                value: this.tipoinclusaotemporariaEnum.MOTIVO_INCLUSAO_LOCAIS_SEM_FILIAIS.toString(),
                label: 'Locais sem filiais',
            },
            {
                value: this.tipoinclusaotemporariaEnum.MOTIVO_INCLUSAO_ESTUDO_MERCADO.toString(),
                label: 'Estudo de mercado',
            },
            {
                value: this.tipoinclusaotemporariaEnum.MOTIVO_INCLUSAO_CONTRAT_SUPERIOR_3_MESES.toString(),
                label: 'Contratação superior a 3 meses',
            }
        ];
    }

    // inicio - tratamento de datas
    private bloqueiaAnoMinimo(): void {
        this.anoMinimo = moment().subtract(120, 'years'); // > 120 anos
    }

    private bloqueiaAnoMaximo(): void {
        this.anoMaximo = moment().add(100, 'years'); // > 120 anos
    }
    // fim - tratamento de datas
}
