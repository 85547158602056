import angular from 'angular';
import * as lodash from 'lodash';
import { FeriasEmGradeEnum } from '../FeriasEmGradeEnum';
import { LoadParams } from '../../../../../shared/models/loadparams.model';
import { IFerias } from '../../models/ferias.model';
import { MeurhEnvioFeriasModalService } from '../enviosmodal/envios.modal.service';
import { conversaoFormatoData } from '../../../../../utils/utils';

export class MeurhFeriasmarcaremgradePassotresController implements angular.IController {
	static $inject = ['$rootScope', '$scope', '$state', '$http', '$stateParams', '$location', '$transitions', 'nsjRouting', 'NewToaster', 'ControleFeriasService', '$sce', 'MeurhEnvioFeriasModalService'];

	public busy: boolean = false;
	public dataAtual: Date = new Date();
	public mesAtual: string = '';
	public anoAtual: string = '';
	public anosSelect: Array<object> = [];
	public mesesSelect: Array<object> = [];
	public configAdiantamento: boolean = this.$rootScope.configuracoes['ADIANTAMENTO_13_FERIAS'];
	public daysOpt: Array<number> = lodash.range(5, 31);
	public erroPreenchimento: boolean = false;
	public entitiesSolicitacoes: Array<IFerias> = [];
	public solicitacoesExibidas: any = [];
	public solicitacoesRascunhoDeletadas: any = [];
	public arraySalvar: Array<IFerias> = [];
	public filters: any;
	public filter: any;
	public constructors: any;
	public entities: Array<IFerias>;
	public passo: number = 3;
	public checkboxPreenchidos: any = [];
	public loadParams: LoadParams = new LoadParams();
	public errosPossiveis: any = {
		calculaDiasFunc: false,
		calculaDiasVendidosFunc: false,
		verificaQtdDiasPeriodoFunc: false,
		verificaSobrepostosFunc: false,
		calculaDiasTotal: false,
	};
	public errosPossiveisMensagens: any = {
		calculaDiasFunc: '',
		calculaDiasVendidosFunc: '',
		verificaQtdDiasPeriodoFunc: '',
		verificaSobrepostosFunc: '',
		calculaDiasTotal: '',
	};
	public fields: any = [
		{
			value: 'identificador',
			label: '',
			type: 'string',
			style: 'title',
			copy: '',
			rowspanNaCelula: true,
			customCollumnScope: {
				desmarcaTodosMesmoIdentificador: (entity: any) => {
					this.desmarcaTodosMesmoIdentificadorFunc(entity);
				},
			},
			customCollumnHtml: (): string => {
				let someHtmlVar = `
                <input type='checkbox' name="salvar"
                ng-model="tableRowEntity.salvar"
                checked="tableRowEntity.salvar"

                ng-change="customCollumnScope.desmarcaTodosMesmoIdentificador(tableRowEntity)"/>`;

				return someHtmlVar;
			},
		},
		{
			value: 'nome',
			label: 'Colaboradores',
			type: 'string',
			style: 'title',
			copy: '',
			rowspanNaCelula: true,
			customCollumnHtml: (): string => {
				let someHtmlVar = `<span ng-bind-template="{{tableRowEntity.nome}}"></span>
                <div><small>Direito a partir de: <span ng-bind-template="{{tableRowEntity.inicioproximoperiodoaquisitivoferias}}"></span></small></div>
                <div><small>Limite para a dobra: <span ng-class="!tableRowEntity.dataproximodolimite && !tableRowEntity.datapassoudolimite ? '' : 'table-color-red'" ng-bind-template="{{tableRowEntity.datalimitegozo | asDate | date:'dd/MM/yyyy'}}"></span>
                <span ng-if="tableRowEntity.dataproximodolimite">
                <i style="color: #C82333;" class="fas fa-info-circle"></i>
                <nsj-tooltip tooltip-position="top">
                    <p>O limite está próximo do vencimento.</p>
                </nsj-tooltip>
                </span>
                <span ng-if="tableRowEntity.datapassoudolimite">
                    <i style="color: #C82333;" class="fas fa-info-circle"></i>
                    <nsj-tooltip tooltip-position="top">
                        <p>O limite foi ultrapassado.</p>
                    </nsj-tooltip>
                </span>
                </small></div>`;

				return someHtmlVar;
			},
		},
		{
			value: 'saldo',
			label: 'Saldo disponível',
			type: 'string',
			style: 'default',
			copy: '',
			rowspanNaCelula: true,
			customCollumnHtml: (): string => {
				let someHtmlVar = `<span>{{tableRowEntity.saldo}} dias</span>`;
				return someHtmlVar;
			},
		},
		{
			value: 'saldoTotal',
			label: 'Saldo total',
			type: 'string',
			style: 'default',
			copy: '',
			rowspanNaCelula: true,
			customCellClass: 'scd-child-shadow',
			customCollumnHtml: (): string => {
				let someHtmlVar = `<span>{{tableRowEntity.saldoTotal}} dias</span>`;
				return someHtmlVar;
			},
		},
		{
			value: 'datainiciogozo',
			label: 'Início de gozo',
			type: 'date',
			style: 'title',
			copy: '',
			customCollumnScope: {
				getDataInicioGozoFormatada: (dataInicioGozo: string): string => {
					return conversaoFormatoData(dataInicioGozo);
				}
			},
			customCollumnHtml: () => {
				let someHtmlVar = `<span ng-if="tableRowEntity.datainiciogozo"> {{ customCollumnScope.getDataInicioGozoFormatada(tableRowEntity.datainiciogozo) }} </span>`;
				return someHtmlVar;
			},
		},
		{
			value: 'diasferiascoletivas',
			label: 'Dias a conceder',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'diasvendidos',
			label: 'Comprar dias?',
			type: 'string',
			style: 'title',
			copy: '',
			customCollumnHtml: (): string => {
				let someHtmlVar = `
                <div ng-if="tableRowEntity.diasvendidos!=='' && tableRowEntity.diasvendidos!==null && tableRowEntity.diasvendidos!==undefined">
                    <span>Sim, {{tableRowEntity.diasvendidos}}</span>
                </div>
                <div ng-if="tableRowEntity.diasvendidos==='' || tableRowEntity.diasvendidos===null || tableRowEntity.diasvendidos===undefined">
                    <span>Não</span>
                </div>
                `;
				return someHtmlVar;
			},
		}
	];

	public arrayModal: Array<IFerias> = [];

	constructor(
		public $rootScope: angular.IRootScopeService & { configuracoes: any },
		public $scope: angular.IScope,
		public $state: angular.ui.IStateService,
		public $http: angular.IHttpService,
		public $stateParams: angular.ui.IStateParamsService,
		public $location: any,
		public $transitions: any,
		public nsjRouting: any,
		public NewToaster: any,
		public entityService: any,
		public $sce: any,
		public MeurhEnvioFeriasModalService: MeurhEnvioFeriasModalService
	) {
		if (this.configAdiantamento) {
			this.fields.push(
				{
					value: 'datafimgozo',
					label: 'Fim de gozo',
					type: 'date',
					style: 'title',
					copy: '',
					customCollumnScope: {
						getDataFimGozoFormatada: (dataFimGozo: string): string => {
							return conversaoFormatoData(dataFimGozo);
						}
					},
					customCollumnHtml: () => {
						let someHtmlVar = `<span ng-if="tableRowEntity.datafimgozo"> {{ customCollumnScope.getDataFimGozoFormatada(tableRowEntity.datafimgozo) }} </span>`;
						return someHtmlVar;
					},
				},
				{
					value: 'adto13nasferias',
					label: 'Adiantar 13º?',
					type: 'string',
					style: 'title',
					copy: '',
					customCollumnHtml: () => {

						let someHtmlVar = `
                <div ng-if="tableRowEntity.adto13nasferias===true">
                    <span>Sim</span>
                </div>
                <div ng-if="tableRowEntity.adto13nasferias===false || tableRowEntity.adto13nasferias==='' || tableRowEntity.adto13nasferias===undefined || tableRowEntity.adto13nasferias===null">
                    <span>Não</span>
                </div>
                `;
						return someHtmlVar;
					},
				},
			);
		}

		entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
	}

	$onInit(): void {
		this.alteraEntities();
		this.onSave();
		this.onDelete();
	}

	isBusy(): boolean {
		return this.busy;
	}

	alteraEntities(): void {
		this.solicitacoesExibidas = this.entitiesSolicitacoes.filter((solicitacao: IFerias): boolean => {
			return solicitacao.situacao === FeriasEmGradeEnum.SITUACAO_RASCUNHO || solicitacao.situacao === '';
		});

		this.entitiesSolicitacoes.forEach((element: IFerias) => {
			element.salvar = true;
		});
	}

	onSave(): void {
		this.$scope.$on('avisoferias_submitted', (event: angular.IAngularEvent, args: any) => {
			let indexSalvo = this.arraySalvar.indexOf(args.entity);
			let indexSolicitacao = this.entitiesSolicitacoes.findIndex((element) => element.countId === args.entity.countId);

			args.entity.erro = false;
			args.entity.descricaoerro = '-';
			this.arrayModal.push(args.entity);

			this.entitiesSolicitacoes.splice(indexSolicitacao, 1);
			this.arraySalvar.splice(indexSalvo, 1);

			let possuiErro = this.arrayModal.some(element => element.erro === true);

			if (this.arraySalvar.length === 0) {
				this.busy = false;
				if (possuiErro) {
					let modalEnvio = this.MeurhEnvioFeriasModalService.open(this.arrayModal);
					modalEnvio.result
						.then((response: any): void => {
							this.calculaPasso(false);
						})
						.catch((msg: any): void => {
							this.NewToaster.pop({
								type: 'success',
								title: msg
							});
							// this.$state.reload();
							this.$state.go('ferias_marcacoes');
						});
				} else {
					this.NewToaster.pop({
						type: 'success',
						title: 'As férias foram criadas com sucesso!'
					});
					// this.$state.reload();
					this.$state.go('ferias_marcacoes');
				}
			}
			this.busy = false;
		});

		this.$scope.$on('avisoferias_submit_error', (event: angular.IAngularEvent, args: any) => {
			let indexSalvo = this.arraySalvar.indexOf(args.entity);

			args.entity.erro = true;
			args.entity.descricaoerro = '';

			if (!!args.response.data?.errors?.children) {
				for (const campo in args.response.data.errors.children) {
					if (args.response.data.errors.children[campo]['errors']) {
						args.response.data.errors.children[campo]['errors'].forEach((element: string) => {
							args.entity.descricaoerro = args.entity.descricaoerro + element + '; ';
						});
					}
				}
			} else {
				args.entity.descricaoerro = 'Não identificado';
			}

			this.arrayModal.push(args.entity);
			this.arraySalvar.splice(indexSalvo, 1);

			if (this.arraySalvar.length === 0) {
				this.busy = false;
				let modalEnvio = this.MeurhEnvioFeriasModalService.open(this.arrayModal);
				modalEnvio.result
					.then((response: any): void => {
						this.calculaPasso(false);
					})
					.catch((msg: any): void => {
						this.NewToaster.pop({
							type: 'success',
							title: msg
						});
						// this.$state.reload();
						this.$state.go('ferias_marcacoes');
					});
			}
			this.busy = false;
		});
	}

	onDelete(): void {
		this.$scope.$on('meurh_solicitacoesferias_deleted', (event: angular.IAngularEvent, args: any) => {
			var indexDeletado = this.solicitacoesRascunhoDeletadas.indexOf(args.entity);
			this.solicitacoesRascunhoDeletadas.splice(indexDeletado, 1);

			if (this.solicitacoesRascunhoDeletadas.length === 0) {
				this.busy = false;
				this.$rootScope.$broadcast('solicitacoesferias_deleted_all', {});
			}
		});

		this.$scope.$on('meurh_solicitacoesferias_delete_error', (event: angular.IAngularEvent, args: any) => {
			this.NewToaster.pop({
				type: 'error',
				title: 'Ocorreu um erro'
			});
		});
	}

	acoesSalvar(rascunho: boolean = false): void {
		this.busy = true;

		if (this.solicitacoesRascunhoDeletadas.length > 0) {
			this.solicitacoesRascunhoDeletadas.forEach((element: IFerias): void => {
				this.entityService.delete(element, true);
			});

			this.$scope.$on('solicitacoesferias_deleted_all', (): void => {
				this.salvar(rascunho);
			});
		} else {
			this.salvar(rascunho);
		}
	}

	salvar(rascunho: any = false): void {
		let countId = 0;
		this.entitiesSolicitacoes.forEach((element: IFerias): void => {
			element.countId = countId++;
		});

		// salvar ou enviar apenas as solicitações em rascunho já existentes e as que estão sendo criadas no momento
		this.entitiesSolicitacoes = this.entitiesSolicitacoes.filter((element: IFerias): boolean => {
			return (element.solicitacao && element.salvar === true && element.situacao === FeriasEmGradeEnum.SITUACAO_RASCUNHO) ||
				(!element.solicitacao && element.salvar);
		});
		this.arraySalvar = angular.copy(this.entitiesSolicitacoes);

		if (this.arraySalvar.length === 0) {
			this.busy = false;
			this.$state.reload();
		} else {
			if (rascunho) {
				this.arraySalvar.forEach((element: IFerias): void => {
					element.situacao = FeriasEmGradeEnum.SITUACAO_RASCUNHO;
				});
			} else {
				this.arraySalvar.forEach((element: IFerias): void => {
					element.situacao = FeriasEmGradeEnum.SITUACAO_PENDENTE;
				});
			}

			this.arraySalvar.forEach((element: IFerias): void => {
				if (element.solicitacao !== '') {
					element['update'] = true;
				}

				if (element.diasferiascoletivas === undefined || element.diasferiascoletivas === null || element.diasferiascoletivas === '') {
					element['diasferiascoletivas'] = 0;
				}

				if (element.datafimgozo === undefined || element.datafimgozo === null || element.datafimgozo === '') {
					element['datafimgozo'] = element['datainiciogozo'];
				}

				if (element.datainiciogozo > element.datafimgozo) {
					element['datafimgozo'] = element['datainiciogozo'];
				}

				this.entityService.save(element, element.trabalhador, '', element.update, {
					empresa: element.empresaobj.empresaobj,
					estabelecimento: element.estabelecimentoobj.estabelecimento
				});
			});
		}
	}

	desmarcaTodosMesmoIdentificadorFunc(entity: IFerias): void {
		let solicitacoesTrabalhador = this.getSolicitacoesTrabalhador(entity);

		solicitacoesTrabalhador.forEach((element: IFerias) => {
			if (entity.salvar === true) {
				element.salvar = true;
			} else {
				element.salvar = false;
			}
		});
	}

	calculaPasso(continua: boolean): void {
		if (continua) {
			this.passo = this.passo + 1;
		} else {
			this.passo = this.passo - 1;
		}
	}

	getSolicitacoesTrabalhador(entity: IFerias): Array<IFerias> {
		return this.entitiesSolicitacoes.filter((element: IFerias) => element.trabalhador === entity.trabalhador);
	}

	peloMenosUmColaboradorSelecionado() {
		let itensSelecionados: IFerias[] = [];

		itensSelecionados = this.entitiesSolicitacoes.filter((element: IFerias): boolean => {
			return (element.solicitacao && element.salvar === true && element.situacao === FeriasEmGradeEnum.SITUACAO_RASCUNHO) ||
				(!element.solicitacao && element.salvar);
		});

		return itensSelecionados.length > 0;
	}

}
